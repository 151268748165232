import React from 'react';
import GlobalContext, { IGlobalContext } from './global.context';
import { getMenus } from '../routes';

/**
 * 全局context的Provider。
 *
 * @export
 * @class GlobalProvider
 * @extends {React.Component<any, IGlobalContext>}
 */
export default class GlobalProvider extends React.Component<any, IGlobalContext> {
  static contextType = GlobalContext;

  dispatch: (key: any, value?: any) => void;

  constructor(props: any) {
    super(props)

    this.dispatch = (key: any, value?: any) => {
      if (typeof key === 'string') {
        const state: any = { [key]: value }
        this.setState(state)
      } else {
        this.setState(key)
      }
    }

    this.state = {
      name: '',
      applicationName: '木里木外后台管理平台',
      menus: getMenus(),
      authCodes: [],
      dispatch: this.dispatch
    }
  }

  render() {
    return <GlobalContext.Provider value={this.state}>{this.props.children}</GlobalContext.Provider>;
  }
}
