import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';


export default  [
  {
    path: routeNames.goodSales,
    component: () => import('../../pages/reportManagement/goodSales'),
    meta: { title: '商品销售统计'
    ,code:EAuthCode.GOOD_SALES
    }
  },
  {
    path: routeNames.userConsume,
    component: () => import('../../pages/reportManagement/userConsume'),
    meta: { title: '用户消费统计'
    ,code:EAuthCode.USER_CONSUME
    }
  }
]  as TRoutes[]
