import { Router, Switch, Route, Redirect } from "react-router-dom";
import { history, routes } from '../../routes';
import { FC, memo } from "react";
import LoadableRouterComponent from "../loadableRouterComponent";
import { isLayoutRoute, RLRoute } from "~/routes/const";

function renderRoute(data: RLRoute[]) {
  return (
    <Switch>
      {data.map((router, index) => {
        // 布局路由
        if (isLayoutRoute(router)) {
          const { layout: Layout, redirect, children = [], path } = router
          return (
            <Route
              key={index}
              render={(props) => (
                <Layout>
                  {(redirect && props.location.pathname === path) && (
                    typeof redirect === 'string'
                      ? <Redirect to={redirect} />
                      : <LoadableRouterComponent {...props} redirect={redirect} />
                  )}
                  {renderRoute(children as RLRoute[])}
                </Layout>
              )}
            />
          )
        }

        const { children, component, meta = {}, ...rest } = router
        if (children) {
          const Component = router.component;
          return (
            <Route key={index}  {...rest}  >
              <Component>
                {renderRoute(children)}
              </Component>
            </Route>
          )
        }

        // console.log(rest.path, rest)
        return (
          <Route
            {...rest}
            key={index}
            exact
            render={(props) => <LoadableRouterComponent {...props} component={component} meta={meta} />}
          />
        )
      })
      }
    </Switch>
  )
}

const Component: FC = () => {
  return (
    <Router history={history}>
      {renderRoute(routes)}
    </Router>
  )
}

Component.defaultProps = {};
Component.displayName = 'RouterLayout';

const RouterLayout = memo(Component);
export default RouterLayout;