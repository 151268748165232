import loadable from '@loadable/component'
import { Spin } from 'antd'
import { Location } from 'MMRouter'
import { createContext, PureComponent, useContext } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { skipAuth } from '~/config'
import globalContext, { IGlobalContext } from '~/contexts/global.context'
import { TMenu } from '~/data-model/common'
import { routeNames, TRoutes } from '~/routes/const'
import { routerAfterEach, routerBeforeEach } from '~/routes/guard'

const Context = createContext({} as Omit<LoadableRouterComponentProps, 'component'>)
let from: Location

interface LoadableRouterComponentProps extends RouteComponentProps<any>, Pick<TRoutes, 'meta'> {
  /* 组件 */
  component?: any
  /** 路由元数据 */
  meta: TRoutes['meta']
  /** 路径转发 */
  redirect?: boolean | string
}

interface LoadableRouterComponentState {
  renderRouter: boolean
}
/**
 * 页面级懒加载路由
 * @export
 * @class LoadableRouterComponent
 * @extends {PureComponent<any>}
 */
export default class LoadableRouterComponent extends PureComponent<LoadableRouterComponentProps, LoadableRouterComponentState> {
  static contextType = globalContext

  state: LoadableRouterComponentState = {
    renderRouter: false
  }

  componentDidMount() {
    // console.log('mounted', this.props.location.pathname)
    const { meta = {}, history, location } = this.props
    const to = { ...location, meta }
    from = from || to

    routerBeforeEach(
      to,
      from,
      (...args) => {
        if (args.length === 0) {
          this.setState({ renderRouter: true }, () => {
            from = to
          })
        } else if (args.length === 1) {
          const [param] = args
          if (param === false) {
            history.goBack()
          } else if (typeof param === 'string') {
            history.push(param)
          } else if (typeof param === 'object') {
            const { replace, ...rest } = param
            replace ? history.replace(rest) : history.push(rest)
          }
        } else if (args.length === 2) {
          history.push(args[0], args[1])
        }
      },
      this.context
    )
  }

  componentDidUpdate() {
    const { meta = {}, location } = this.props
    const to = { ...location, meta }
    from = from || to
    if (this.state.renderRouter) {
      routerAfterEach(to, from, this.context)
    }
  }

  render() {
    const { component, redirect, ...others } = this.props
    const { meta = {}, ...rest } = others
    const { authCodes, menus } = this.context as IGlobalContext

    // console.log(this.state.renderRouter, 'renderRouter')
    if (!this.state.renderRouter) {
      return this.loading
    }

    if (typeof redirect === 'boolean' && redirect) {
      const firstMenuPath = this.getFirstPathFromMenus(menus) || routeNames[404]
      return <Redirect to={{ pathname: firstMenuPath }} />
    }

    if (rest.location.pathname === '/') {
      return null
    }

    // 校验权限code
    let metaCode: string[] = []
    metaCode = meta.code ? metaCode.concat(meta.code) : metaCode
    if (metaCode.length && authCodes.every((ac) => metaCode.indexOf(ac) === -1) && !skipAuth) {
      return <Redirect to={{ pathname: routeNames[403] }} />
    }

    const Com = meta.sync ? component : loadable(component, { fallback: this.loading })
    return (
      <Context.Provider value={others}>
        <Com {...rest} />
      </Context.Provider>
    )
  }

  loading = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Spin tip="加载中" />
    </div>
  )

  getFirstPathFromMenus(menus: TMenu[]) {
    let firstPath = ''
    for (let index = 0; index < menus.length; index++) {
      const element = menus[index]
      const { path, children = [] } = element as any
      if (path) {
        firstPath = path
        break
      } else if (children.length) {
        const fp = this.getFirstPathFromMenus(children)
        if (fp) {
          firstPath = fp
          break
        }
      }
    }
    return firstPath
  }
}

export function useRouter() {
  const context = useContext(Context)
  return context
}
