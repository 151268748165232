import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';


export default [
  {
    path: routeNames.contractManagement,
    component: () => import('../../pages/projectManagement/contractManagement'),
    meta: {
      title: '合同列表',
      code:EAuthCode.CONTRACT_MANAGEMENT

    }
  },
  {
    path: routeNames.introduceCostRecordParentCompany,
    component: () => import('../../pages/projectManagement/introduceCostRecordParentCompany'),
    meta: {
      title: '介绍费记录-总公司',
      code:EAuthCode.INTRODUCE_COST_RECORD_PARENT_COMPANY

    }
  },
  {
    path: routeNames.introduceCostRecordSubsidiary,
    component: () => import('../../pages/projectManagement/introduceCostRecordSubsidiary'),
    meta: {
      title: '介绍费记录-子公司',
      code:EAuthCode.INTRODUCE_COST_RECORD_SUBSIDIARY

    }
  },
  {
    path: routeNames.projectManagement,
    component: () => import('../../pages/projectManagement/projectManagement'),
    meta: {
      title: '项目管理',
      code:EAuthCode.PROJECT_LIST_MANAGEMENT

    }
  },
  {
    path: routeNames.projectData,
    component: () => import('../../pages/projectManagement/projectData'),
    meta: {
      title: '项目阶段详情',
      hideInMenu: true
      // code:EAuthCode.PROJECT_DATA
    }
  },
  {
    path: routeNames.orderList,
    component: () => import('../../pages/projectManagement/orderList'),
    meta: {
      title: '订单列表',
      hideInMenu: true
    }
  },
  {
    path: routeNames.rightsManagement,
    component: () => import('../../pages/projectManagement/rightsManagement'),
    meta: {
      title: '权益管理',
      code:EAuthCode.RIGHTS_MANAGEMENT

    }
  },
  {
    path: routeNames.rightsForm,
    component: () => import('../../pages/projectManagement/rightsForm'),
    meta: {
      title: '新建权益',
      hideInMenu: true
      // code:EAuthCode.RIGHTS_FORM
    }
  },
  {
    path: routeNames.rightsRecord,
    component: () => import('../../pages/projectManagement/rightsRecord'),
    meta: {
      title: '权益发放记录-总公司',
      code:EAuthCode.RIGHTS_RECORD

    }
  },
  {
    path: routeNames.rightsRecordSubsidiary,
    component: () => import('../../pages/projectManagement/rightsRecordSubsidiary'),
    meta: {
      title: '权益发放记录-子公司',
      code:EAuthCode.RIGHTS_RECORD_SUBSIDIARY


    }
  }
] as TRoutes[]
