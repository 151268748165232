import { EAuthCode } from '~/enums/EAuthCode';
import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  // {
  //   path: routeNames.departmentManagement,
  //   component: () => import('../../pages/departmentManagement'),
  //   meta: { title: '部门管理', code: EAuthCode.PermissionsDept }
  // },
  {
    path: routeNames.employeeManagement,
    component: () => import('../../pages/departmentManagement/employeeManagement'),
    meta: { title: '用户管理'
      ,code: EAuthCode.USER_MANAGEMENT
    }
  },
  {
    path: routeNames.roleManagement,
    component: () => import('../../pages/departmentManagement/roleManagement'),
    meta: { title: '角色管理'
      ,code: EAuthCode.ROLE_MANAGEMENT
    }
  },
  {
    path: routeNames.accountManagement,
    component: () => import('../../pages/departmentManagement/SharedAccountManagement'),
    meta: {
      title: '纷享销客账号管理'
      // ,
      // code: EAuthCode.SHARED_ACCOUNT_MANAGEMENT
    }
  },
  {
    path: routeNames.childrenAccount,
    component: () => import('../../pages/departmentManagement/SharedAccountManagement/childrenAccount'),
    meta: {
      title: '纷享销客子账号管理',
      hideInMenu: true
      // ,
      // code: EAuthCode.SHARED_ACCOUNT_CHILDREN_MANAGEMENT
    }
  }
] as TRoutes[]