export const isDevelopment = process.env.NODE_ENV === 'development';

export const apiUrl = isDevelopment ? '/admin' : 'https://mem.muli.group/admin';

export const aliyunOssTokenUrl = 'common/muliCommon/info'; //`/oss/info`;

export const defaultPwd = 'ocj123456'; // 默认密码

export const publicPath = '/'

//导出功能路径 更换地址时需要修改
// export const commenUrl = isDevelopment ? 'http://172.18.58.61:10001/' : 'https://mem.muli.group/'
// export const commenUrl = isDevelopment ? 'http://172.20.10.2:10001/' : 'https://mem.muli.group/'
export const commenUrl = !isDevelopment ? 'http://172.18.58.61:10001/' : 'https://mem.muli.group/'
//'http://admin.mulimuwai.t2.wmeimob.cn/'//

/**
 * 是否跳过权限验证
 * @warning 允许在接口无法调用时继续本地开发.只允许在本地开发时使用
 */ 
export const skipAuth = process.env.NODE_ENV === 'development' && false