import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';


export default  [
  {
    path: routeNames.muliSetting,
    component: () => import('../../pages/muliManagement/muliSetting'),
    meta: { title: '木里值设置'
        , code: EAuthCode.MULI_SETTING 
      }
  },
  {
    path: routeNames.muliDetails,
    component: () => import('../../pages/muliManagement/muliDetails'),
    meta: { title: '木里值明细'
      , code: EAuthCode.MULI_DETAILS 
    }
  },
  {
    path: routeNames.activityIssueList,
    component: () => import('../../pages/muliManagement/activityIssueList'),
    meta: { title: '参加活动发放木里值'
     , code: EAuthCode.ACTIVITY_ISSUE_LIST
    }
  }, {
    path: routeNames.issueTable,
    component: () => import('../../pages/muliManagement/issueTable'),
    meta: { title: '发放名单',hideInMenu:true
    }
  }
]  as TRoutes[]
