import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.activityManagement,
    component: () => import('../../pages/brandActivityManagement/activityManagement'),
    meta: { title: '线下品牌活动管理' 
      , code: EAuthCode.ACTIVITY_MANAGEMENT
    }
  },
  {
    path: routeNames.addActivityForm,
    component: () => import('../../pages/brandActivityManagement/addActivityForm'),
    meta: { title: '新建活动', hideInMenu: true }
  },
  {
    path: routeNames.signInList,
    component: () => import('../../pages/brandActivityManagement/signInList'),
    meta: { title: '活动报名名单', hideInMenu: true }
  }
] as TRoutes[]
