import { FC, memo, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import globalContext from '~/contexts/global.context';
import { getMenuCrumbs } from '~/utils/menu';
import HeaderBar from '../headerBar';
import styles from './index.module.less';

export interface IProps { }

const Component: FC<IProps> = (props) => {

  const context = useContext(globalContext);
  const location = useLocation()

  const crumbs = useMemo(() => {
    const crumbs = getMenuCrumbs(context.menus, location.pathname).map(item => item.title)
    return crumbs.length ? [crumbs[1]] : [];
  }, [context.menus, location])

  const isRenderHeader = !!crumbs.length;
  return (
    <div className={styles.component}>
      {isRenderHeader && <HeaderBar crumbs={crumbs} />}
      <div className={isRenderHeader ? styles.content : ''}>{props.children}</div>
    </div>
  )
}

Component.defaultProps = {};
Component.displayName = 'ContentLayout';

const ContentLayout = memo(Component);
export default ContentLayout;