import { IRequestConfig } from "~/modules/@wmeimob/request/src/types/fetch-type";
import {
  ApiBacklogAffirmPostParams,
  ApiBacklogQueryGetParams,
  ApiBacklogSetRemarkPostParams,
  ApiBannerDeleteDeleteParams,
  ApiBannerQueryGetParams,
  ApiBasicconfigBasicQueryGetParams,
  ApiBasicconfigContentManageGetParams,
  ApiBasicconfigIntegralManageGetParams,
  ApiBasicconfigMuliManageGetParams,
  ApiBasicconfigShoppingNoticeGetParams,
  ApiBasicconfigTransferManageGetParams,
  ApiBrandactivityDeleteDeleteParams,
  ApiBrandactivitylistQueryGetParams,
  ApiBrandactivityQueryBrandActivityListExportGetParams,
  ApiBrandactivityQueryBrandActivityListGetParams,
  ApiBrandactivityQueryExportGetParams,
  ApiBrandactivityQueryGetParams,
  ApiFrisclassifyDeleteDeleteParams,
  ApiFrisclassifyQueryGetParams,
  ApiFrisclassifyQueryPullDownGetParams,
  ApiGifbagQueryGetParams,
  ApiGifcardQueryGetParams,
  ApiGifinfoDeleteDeleteParams,
  ApiGifinfoQueryGetParams,
  ApiGifpackageDeleteDeleteParams,
  ApiGifpackagemappingQueryGetParams,
  ApiGifpackageQueryGetParams,
  ApiGifpackageQueryGiftListGetParams,
  ApiGoodarextendQueryGetParams,
  ApiGoodinfoDeleteDeleteParams,
  ApiGoodinfoQueryFrisclassifyGetParams,
  ApiGoodinfoQueryGetParams,
  ApiGoodinfoQuerySecondClassifyGetParams,
  ApiGoodstatisticsQueryGoodstatisticsExportGetParams,
  ApiGoodstatisticsQueryGoodstatisticsGetParams,
  ApiInitiateinvitationlogQueryGetParams,
  ApiMulipoinitemMulipoinitemExportGetParams,
  ApiMulipoinitemQueryGetParams,
  ApiMulipoinitemQueryHeadListGetParams,
  ApiMulipriceactivityQueryGetParams,
  ApiMulipriceitemMulipriceitemExportGetParams,
  ApiMulipriceitemMuliPriceSendLogExportGetParams,
  ApiMulipriceitemMuliPriceSendLogGetParams,
  ApiMulipriceitemQueryGetParams,
  ApiMulipricesendlogQueryGetParams,
  ApiMulitvDeleteDeleteParams,
  ApiMulitvQueryGetParams,
  ApiOrderaftersaleQueryGetParams,
  ApiOrdergoodQueryGetParams,
  ApiOrderinfoOrderDetailGetParams,
  ApiOrderinfoQueryGetParams,
  ApiOrderlogisticsQueryGetParams,
  ApiOrderpayQueryGetParams,
  ApiProjeccontractContractGdApprovePostParams,
  ApiProjeccontractCreateProjectIntroduceAmountPostParams,
  ApiProjeccontractQueryGetParams,
  ApiProjeccontractQueryProjectInfoByContractGetParams,
  ApiProjeccontractQueryProjectOrderInfoByContractGetParams,
  ApiProjecequityinfoAddProjectEquityPostParams,
  ApiProjecequityinfoDeleteProjectEquityDeleteParams,
  ApiProjecequityinfoProjectEquityEnablePostParams,
  ApiProjecequityinfoQueryGetParams,
  ApiProjecequityinfoUpdateProjectEquityPostParams,
  ApiProjecequityrecordAuditPostParams,
  ApiProjecequityrecordQueryExportGetParams,
  ApiProjecequityrecordQueryGetParams,
  ApiProjecequityrecordQueryOtherExportGetParams,
  ApiProjecequityrecordQueryOtherListGetParams,
  ApiProjecequityrecordUpdatePayUserPostParams,
  ApiProjecequityrecordUploadPayVoucherPostParams,
  ApiProjecequityrecordUseEquityRecordPostParams,
  ApiProjecinfoextendQueryGetParams,
  ApiProjecinfoProjectGrantEquityPostParams,
  ApiProjecinfoQueryContractInfoByProjectGetParams,
  ApiProjecinfoQueryDetailGetParams,
  ApiProjecinfoQueryGetParams,
  ApiProjecinfoQueryProjectMaterialInfoByProjectGetParams,
  ApiProjecinfoQueryProjectOrderInfoByProjectGetParams,
  ApiProjecintroducefeeAuditPostParams,
  ApiProjecintroducefeeQueryExportGetParams,
  ApiProjecintroducefeeQueryGetParams,
  ApiProjecintroducefeeQueryOtherExportGetParams,
  ApiProjecintroducefeeQueryOtherGetParams,
  ApiProjecintroducefeeUpdatePayUserPostParams,
  ApiProjecintroducefeeUploadPayVoucherPostParams,
  ApiProjecorderProjectGrantEquityPostParams,
  ApiProjecorderQueryGetParams,
  ApiProjecorderQueryProjectContractInfoByOrderGetParams,
  ApiProjecorderQueryProjectInfoByOrderGetParams,
  ApiRichtextQueryGetParams,
  ApiSecondclassifyDeleteDeleteParams,
  ApiSecondclassifyQueryGetParams,
  ApiSysdeptDeleteDeleteParams,
  ApiSysdeptQueryGetParams,
  ApiSysRoleDeleteDeleteParams,
  ApiSysRoleQueryAllGetParams,
  ApiSysRoleQueryGetParams,
  ApiSysStoreUserGroupDeleteDeleteParams,
  ApiSysStoreUserGroupDetailGetParams,
  ApiSysStoreUserGroupQueryGetParams,
  ApiSysUserDeleteDeleteParams,
  ApiSysUserQueryGetParams,
  ApiSysUserResetDefaultDeleteParams,
  ApiUseraddressQueryGetParams,
  ApiUsercollectQueryGetParams,
  ApiUsergifbagDeliverGetParams,
  ApiUsergifbagQueryConvertRecordExportGetParams,
  ApiUsergifbagQueryConvertRecordGetParams,
  ApiUsergifbagQueryGiftDetailGetParams,
  ApiUsergifbagQuerySendRecordExportGetParams,
  ApiUsergifbagQuerySendRecordGetParams,
  ApiUserinfoQueryGetParams,
  ApiUserinfoQueryUserAddressGetParams,
  ApiUserinfoQueryUserInfoExportGetParams,
  ApiUserinfoSetBlackGoldMemberPostParams,
  ApiUserinvitedrecordQueryGetParams,
  ApiUserinvitedrecordUserinvitedrecordExportGetParams,
  ApiUserlevellogQueryGetParams,
  ApiUserssignQueryGetParams,
  ApiUserstatisticsQueryUserStatisticsExportGetParams,
  ApiUserstatisticsQueryUserStatisticsGetParams,
  Backlog,
  Banner,
  BannerDto,
  BasicConfig,
  BrandActivity,
  BrandActivityDto,
  BrandActivityList,
  BrandActivityListDto,
  Frisclassify,
  FrisclassifyDto,
  Gifbag,
  Gifcard,
  Gifinfo,
  GifinfoDto,
  Gifpackage,
  GifpackageDto,
  GifpackageMapping,
  GifpackageMappingDto,
  GoodArextend,
  GoodInfo,
  GoodInfoDto,
  InitiateInvitationLog,
  JsonResult,
  JsonResultBacklog,
  JsonResultBanner,
  JsonResultBasicConfig,
  JsonResultBrandActivity,
  JsonResultBrandActivityList,
  JsonResultFrisclassify,
  JsonResultGifbag,
  JsonResultGifcard,
  JsonResultGifinfo,
  JsonResultGifpackage,
  JsonResultGifpackageMapping,
  JsonResultGoodArextend,
  JsonResultGoodInfo,
  JsonResultInitiateInvitationLog,
  JsonResultListBrandActivityDto,
  JsonResultListFrisclassifyDto,
  JsonResultListGifpackageMappingDto,
  JsonResultListGoodInfoDto,
  JsonResultListProjecequityInfoDto,
  JsonResultListSecondClassify,
  JsonResultListSysDeptHaveUserVo,
  JsonResultListSysDeptVo,
  JsonResultListSysMenuVo,
  JsonResultListSysRoleDto,
  JsonResultListSysUserVo,
  JsonResultListUserAddress,
  JsonResultListUserGifbagRecordDto,
  JsonResultListUserInfo,
  JsonResultLong,
  JsonResultMapStringObject,
  JsonResultMapStringString,
  JsonResultMuliPoinitem,
  JsonResultMuliPoinitemHeadDto,
  JsonResultMuliPriceActivity,
  JsonResultMuliPriceItem,
  JsonResultMuliPriceSendLog,
  JsonResultMuliTv,
  JsonResultObject,
  JsonResultOrderAfterSale,
  JsonResultOrderGood,
  JsonResultOrderInfo,
  JsonResultOrderInfoDto,
  JsonResultOrderLogistics,
  JsonResultOrderPay,
  JsonResultPageInfoBacklogDto,
  JsonResultPageInfoBanner,
  JsonResultPageInfoBrandActivityDto,
  JsonResultPageInfoBrandActivityListDto,
  JsonResultPageInfoFrisclassify,
  JsonResultPageInfoGifcardDto,
  JsonResultPageInfoGifinfoDto,
  JsonResultPageInfoGoodInfoDto,
  JsonResultPageInfoMuliPoinitemDto,
  JsonResultPageInfoMuliPriceActivityDto,
  JsonResultPageInfoMuliPriceItemDto,
  JsonResultPageInfoMuliPriceSendLogDto,
  JsonResultPageInfoMuliTv,
  JsonResultPageInfoOrderAfterSaleDto,
  JsonResultPageInfoOrderInfoDto,
  JsonResultPageInfoProjeccontractDto,
  JsonResultPageInfoProjecequityInfoDto,
  JsonResultPageInfoProjecequityRecordDto,
  JsonResultPageInfoProjecinfoDto,
  JsonResultPageInfoProjecintroduceFeeDto,
  JsonResultPageInfoProjecorderDto,
  JsonResultPageInfoSecondClassify,
  JsonResultPageInfoShareAccount,
  JsonResultPageInfoShareAccountContact,
  JsonResultPageInfoSysDeptVo,
  JsonResultPageInfoSysRoleDto,
  JsonResultPageInfoSysStoreUserGroupVo,
  JsonResultPageInfoSysUserVo,
  JsonResultPageInfoUserAddress,
  JsonResultPageInfoUserGifbagDto,
  JsonResultPageInfoUserInvitedRecordDto,
  JsonResultPhoneBindLog,
  JsonResultProjeccontract,
  JsonResultProjecequityInfo,
  JsonResultProjecequityRecord,
  JsonResultProjecinfo,
  JsonResultProjecinfoDto,
  JsonResultProjecinfoExtend,
  JsonResultProjecintroduceFee,
  JsonResultProjecmaterial,
  JsonResultProjecmaterialDto,
  JsonResultProjecorder,
  JsonResultRichText,
  JsonResultSecondClassify,
  JsonResultString,
  JsonResultSysAuthVo,
  JsonResultSysStoreUserGroupVo,
  JsonResultUserAddress,
  JsonResultUserCollect,
  JsonResultUserGifbag,
  JsonResultUserInfo,
  JsonResultUserInvitedRecord,
  JsonResultUserLevelLog,
  JsonResultUsersSign,
  JsonResultVoid,
  LoginDto,
  MuliPoinitem,
  MuliPriceActivity,
  MuliPriceItem,
  MuliPriceSendLog,
  MuliTv,
  MuliTvDto,
  OrderAfterSale,
  OrderGood,
  OrderInfo,
  OrderInfoDto,
  OrderLogistics,
  OrderPay,
  PhoneBindLog,
  Projeccontract,
  ProjecequityInfo,
  ProjecequityRecord,
  Projecinfo,
  ProjecinfoExtend,
  ProjecintroduceFee,
  Projecmaterial,
  Projecorder,
  RegisterDto,
  ResetPwdParam,
  RichText,
  SecondClassify,
  SecondClassifyDto,
  ShareAccount,
  ShareAccountContact,
  ShareaccountcontactQueryGetParams,
  ShareaccountQueryGetParams,
  ShareaccountQueryListExportGetParams,
  SysDeptAddParam,
  SysRoleAddParam,
  SysStoreUserGroupAddParam,
  SysUserAddParam,
  UserAddress,
  UserCollect,
  UserGifbag,
  UserInfo,
  UserInfoDto,
  UserInvitedRecord,
  UserLevelLog,
  UsersSign
} from "./data-contracts";
import requestInstance from "./instance";

type RequestConfig = Omit<IRequestConfig, "url" | "method">;

export const API = {
  /**
   * No description
   * @name POST /admin/api/backlog
   * @summary 新增
   * @tags 待办事项表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/backlog_POST": (t: Backlog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/backlog`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/backlog
   * @summary 修改
   * @tags 待办事项表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/backlog_PUT": (t: Backlog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/backlog`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 传一个数组list 字段名：idList
   * @name POST /admin/api/backlog/affirm
   * @summary 批量待办事项回访
   * @tags 待办事项表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/backlog/affirm_POST": (query: ApiBacklogAffirmPostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/backlog/affirm`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/backlog/query
   * @summary 待办事项表列表
   * @tags 待办事项表
   * @response `200` `JsonResultPageInfoBacklogDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/backlog/query_GET": (query: ApiBacklogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/backlog/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBacklogDto>,
  /**
   * @description 传一个主键ID 备注 字段名：id remark
   * @name POST /admin/api/backlog/setRemark
   * @summary 待办事项备注
   * @tags 待办事项表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/backlog/setRemark_POST": (query: ApiBacklogSetRemarkPostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/backlog/setRemark`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/backlog/{id}
   * @summary 详情
   * @tags 待办事项表
   * @response `200` `JsonResultBacklog` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/backlog/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/backlog/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultBacklog>,
  /**
   * No description
   * @name PUT /admin/api/backlog/{id}
   * @summary 修改
   * @tags 待办事项表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/backlog/{id}_PUT": (id: number, t: Backlog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/backlog/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/backlog/{id}
   * @summary 删除
   * @tags 待办事项表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/backlog/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/backlog/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/banner
   * @summary 新增
   * @tags 首页顶部banner管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/banner_POST": (t: Banner, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/banner
   * @summary 修改
   * @tags 首页顶部banner管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/banner_PUT": (t: Banner, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/banner/add
   * @summary 首页banner新增
   * @tags 首页顶部banner管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/banner/add_POST": (entity: BannerDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner/add`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/banner/delete
   * @summary 首页banner删除
   * @tags 首页顶部banner管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/banner/delete_DELETE": (query: ApiBannerDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/banner/query
   * @summary 首页banner管理分页查询
   * @tags 首页顶部banner管理
   * @response `200` `JsonResultPageInfoBanner` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/banner/query_GET": (query: ApiBannerQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBanner>,
  /**
   * No description
   * @name PUT /admin/api/banner/update
   * @summary 首页banner修改
   * @tags 首页顶部banner管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/banner/update_PUT": (entity: BannerDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner/update`,
      method: "PUT",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description id：主键id ,bannerStatus: 状态 0 禁用 1启用
   * @name POST /admin/api/banner/updateBannerStatus
   * @summary 首页banner启用/禁用
   * @tags 首页顶部banner管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/banner/updateBannerStatus_POST": (entity: BannerDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner/updateBannerStatus`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description ids：主键id逗号分隔 ,bannerStatus: 状态 0 禁用 1启用
   * @name POST /admin/api/banner/updateBatchBannerStatus
   * @summary 首页banner批量启用/禁用
   * @tags 首页顶部banner管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/banner/updateBatchBannerStatus_POST": (entity: BannerDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner/updateBatchBannerStatus`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/banner/{id}
   * @summary 详情
   * @tags 首页顶部banner管理
   * @response `200` `JsonResultBanner` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/banner/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultBanner>,
  /**
   * No description
   * @name PUT /admin/api/banner/{id}
   * @summary 修改
   * @tags 首页顶部banner管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/banner/{id}_PUT": (id: number, t: Banner, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/banner/{id}
   * @summary 删除
   * @tags 首页顶部banner管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/banner/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/banner/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/basicconfig
   * @summary 新增
   * @tags 基础配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig_POST": (t: BasicConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/basicconfig
   * @summary 修改
   * @tags 基础配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig_PUT": (t: BasicConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/basicconfig/
   * @summary 更新配置字段內容
   * @tags 基础配置
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig/_POST": (data: Record<string, string>, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig/`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/basicconfig/basicQuery
   * @summary 基本设置
   * @tags 基础配置
   * @response `200` `JsonResultMapStringString` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig/basicQuery_GET": (query: ApiBasicconfigBasicQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig/basicQuery`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMapStringString>,
  /**
   * No description
   * @name GET /admin/api/basicconfig/contentManage
   * @summary 内容管理基础设置
   * @tags 基础配置
   * @response `200` `JsonResultMapStringString` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig/contentManage_GET": (query: ApiBasicconfigContentManageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig/contentManage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMapStringString>,
  /**
   * No description
   * @name GET /admin/api/basicconfig/integralManage
   * @summary 积分设置
   * @tags 基础配置
   * @response `200` `JsonResultMapStringString` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig/integralManage_GET": (query: ApiBasicconfigIntegralManageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig/integralManage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMapStringString>,
  /**
   * No description
   * @name GET /admin/api/basicconfig/muliManage
   * @summary 木里值设置
   * @tags 基础配置
   * @response `200` `JsonResultMapStringString` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig/muliManage_GET": (query: ApiBasicconfigMuliManageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig/muliManage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMapStringString>,
  /**
   * No description
   * @name GET /admin/api/basicconfig/shoppingNotice
   * @summary 购买须知
   * @tags 基础配置
   * @response `200` `JsonResultMapStringString` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig/shoppingNotice_GET": (query: ApiBasicconfigShoppingNoticeGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig/shoppingNotice`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMapStringString>,
  /**
   * No description
   * @name GET /admin/api/basicconfig/transferManage
   * @summary 转账管理
   * @tags 基础配置
   * @response `200` `JsonResultMapStringString` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig/transferManage_GET": (query: ApiBasicconfigTransferManageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig/transferManage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMapStringString>,
  /**
   * No description
   * @name GET /admin/api/basicconfig/{id}
   * @summary 详情
   * @tags 基础配置
   * @response `200` `JsonResultBasicConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultBasicConfig>,
  /**
   * No description
   * @name PUT /admin/api/basicconfig/{id}
   * @summary 修改
   * @tags 基础配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/basicconfig/{id}_PUT": (id: number, t: BasicConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/basicconfig/{id}
   * @summary 删除
   * @tags 基础配置
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/basicconfig/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/basicconfig/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/brandactivity
   * @summary 新增
   * @tags 品牌活动管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity_POST": (t: BrandActivity, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/brandactivity
   * @summary 修改
   * @tags 品牌活动管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity_PUT": (t: BrandActivity, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/brandactivity/add
   * @summary 品牌活动新增
   * @tags 品牌活动管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity/add_POST": (entity: BrandActivityDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity/add`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/brandactivity/delete
   * @summary 品牌活动删除
   * @tags 品牌活动管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/brandactivity/delete_DELETE": (query: ApiBrandactivityDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/brandactivity/importBrandActivityList
   * @summary 活动报名名单导入
   * @tags 品牌活动管理
   * @response `200` `void` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity/importBrandActivityList_POST": (file: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity/importBrandActivityList`,
      method: "POST",
      data: file,
      ...options
    }) as unknown as Promise<void>,
  /**
   * @description 查询字段活动名称 activityName 编号 id
   * @name GET /admin/api/brandactivity/query
   * @summary 品牌活动管理分页查询
   * @tags 品牌活动管理
   * @response `200` `JsonResultPageInfoBrandActivityDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity/query_GET": (query: ApiBrandactivityQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBrandActivityDto>,
  /**
   * @description 查询字段用户姓名/手机号/昵称 使用userName字段  活动id=brandActivityId 必传
   * @name GET /admin/api/brandactivity/queryBrandActivityList
   * @summary 活动报名名单查询
   * @tags 品牌活动管理
   * @response `200` `JsonResultPageInfoBrandActivityListDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity/queryBrandActivityList_GET": (
    query: ApiBrandactivityQueryBrandActivityListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/brandactivity/queryBrandActivityList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBrandActivityListDto>,
  /**
   * @description brandActivityId 活动ID
   * @name GET /admin/api/brandactivity/queryBrandActivityListExport
   * @summary 活动报名名单导出
   * @tags 品牌活动管理
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity/queryBrandActivityListExport_GET": (
    query: ApiBrandactivityQueryBrandActivityListExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/brandactivity/queryBrandActivityListExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * @description 查询字段活动名称 activityName 编号 id
   * @name GET /admin/api/brandactivity/queryExport
   * @summary 品牌活动管理分页导出查询
   * @tags 品牌活动管理
   * @response `200` `JsonResultListBrandActivityDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity/queryExport_GET": (query: ApiBrandactivityQueryExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity/queryExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListBrandActivityDto>,
  /**
   * @description 主键id必传
   * @name PUT /admin/api/brandactivity/update
   * @summary 品牌活动修改
   * @tags 品牌活动管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity/update_PUT": (dto: BrandActivityDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity/update`,
      method: "PUT",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 主键id必传  joinStatus 0待审批 1参加 2不参加
   * @name PUT /admin/api/brandactivity/updateJoinStatus
   * @summary 活动参加/不参加状态修改
   * @tags 品牌活动管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity/updateJoinStatus_PUT": (dto: BrandActivityListDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity/updateJoinStatus`,
      method: "PUT",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/brandactivity/{id}
   * @summary 详情
   * @tags 品牌活动管理
   * @response `200` `JsonResultBrandActivity` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultBrandActivity>,
  /**
   * No description
   * @name PUT /admin/api/brandactivity/{id}
   * @summary 修改
   * @tags 品牌活动管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivity/{id}_PUT": (id: number, t: BrandActivity, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/brandactivity/{id}
   * @summary 删除
   * @tags 品牌活动管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/brandactivity/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivity/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/brandactivitylist
   * @summary 新增
   * @tags 活动报名名单
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivitylist_POST": (t: BrandActivityList, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivitylist`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/brandactivitylist
   * @summary 修改
   * @tags 活动报名名单
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivitylist_PUT": (t: BrandActivityList, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivitylist`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/brandactivitylist/query
   * @summary 活动报名名单分页查询
   * @tags 活动报名名单
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivitylist/query_GET": (query: ApiBrandactivitylistQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivitylist/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/brandactivitylist/{id}
   * @summary 详情
   * @tags 活动报名名单
   * @response `200` `JsonResultBrandActivityList` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivitylist/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivitylist/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultBrandActivityList>,
  /**
   * No description
   * @name PUT /admin/api/brandactivitylist/{id}
   * @summary 修改
   * @tags 活动报名名单
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/brandactivitylist/{id}_PUT": (id: number, t: BrandActivityList, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivitylist/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/brandactivitylist/{id}
   * @summary 删除
   * @tags 活动报名名单
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/brandactivitylist/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/brandactivitylist/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/frisclassify
   * @summary 新增
   * @tags 分类管理表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/frisclassify_POST": (t: Frisclassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/frisclassify`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/frisclassify
   * @summary 修改
   * @tags 分类管理表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/frisclassify_PUT": (t: Frisclassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/frisclassify`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/frisclassify/add
   * @summary 分类管理新增
   * @tags 分类管理表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/frisclassify/add_POST": (dto: FrisclassifyDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/frisclassify/add`,
      method: "POST",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/frisclassify/delete
   * @summary 分类管理删除
   * @tags 分类管理表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/frisclassify/delete_DELETE": (query: ApiFrisclassifyDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/frisclassify/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/frisclassify/query
   * @summary 分类管理表分页查询
   * @tags 分类管理表
   * @response `200` `JsonResultPageInfoFrisclassify` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/frisclassify/query_GET": (query: ApiFrisclassifyQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/frisclassify/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoFrisclassify>,
  /**
   * No description
   * @name GET /admin/api/frisclassify/queryPullDown
   * @summary 分类下拉列表
   * @tags 分类管理表
   * @response `200` `JsonResultListFrisclassifyDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/frisclassify/queryPullDown_GET": (query: ApiFrisclassifyQueryPullDownGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/frisclassify/queryPullDown`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListFrisclassifyDto>,
  /**
   * No description
   * @name PUT /admin/api/frisclassify/update
   * @summary 分类管理修改
   * @tags 分类管理表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/frisclassify/update_PUT": (dto: FrisclassifyDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/frisclassify/update`,
      method: "PUT",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/frisclassify/{id}
   * @summary 详情
   * @tags 分类管理表
   * @response `200` `JsonResultFrisclassify` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/frisclassify/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/frisclassify/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultFrisclassify>,
  /**
   * No description
   * @name PUT /admin/api/frisclassify/{id}
   * @summary 修改
   * @tags 分类管理表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/frisclassify/{id}_PUT": (id: number, t: Frisclassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/frisclassify/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/frisclassify/{id}
   * @summary 删除
   * @tags 分类管理表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/frisclassify/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/frisclassify/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/gifbag
   * @summary 新增
   * @tags 礼包表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifbag_POST": (t: Gifbag, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifbag`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/gifbag
   * @summary 修改
   * @tags 礼包表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifbag_PUT": (t: Gifbag, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifbag`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/gifbag/query
   * @summary 礼包表分页查询
   * @tags 礼包表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifbag/query_GET": (query: ApiGifbagQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifbag/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/gifbag/{id}
   * @summary 详情
   * @tags 礼包表
   * @response `200` `JsonResultGifbag` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifbag/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifbag/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultGifbag>,
  /**
   * No description
   * @name PUT /admin/api/gifbag/{id}
   * @summary 修改
   * @tags 礼包表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifbag/{id}_PUT": (id: number, t: Gifbag, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifbag/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/gifbag/{id}
   * @summary 删除
   * @tags 礼包表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/gifbag/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifbag/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/gifcard
   * @summary 新增
   * @tags 礼品卡
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifcard_POST": (t: Gifcard, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifcard`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/gifcard
   * @summary 修改
   * @tags 礼品卡
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifcard_PUT": (t: Gifcard, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifcard`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/gifcard/query
   * @summary 礼品卡分页查询
   * @tags 礼品卡
   * @response `200` `JsonResultPageInfoGifcardDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifcard/query_GET": (query: ApiGifcardQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifcard/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoGifcardDto>,
  /**
   * No description
   * @name GET /admin/api/gifcard/{id}
   * @summary 详情
   * @tags 礼品卡
   * @response `200` `JsonResultGifcard` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifcard/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifcard/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultGifcard>,
  /**
   * No description
   * @name PUT /admin/api/gifcard/{id}
   * @summary 修改
   * @tags 礼品卡
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifcard/{id}_PUT": (id: number, t: Gifcard, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifcard/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/gifcard/{id}
   * @summary 删除
   * @tags 礼品卡
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/gifcard/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifcard/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/gifinfo
   * @summary 新增
   * @tags 礼品表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifinfo_POST": (t: Gifinfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifinfo`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/gifinfo
   * @summary 修改
   * @tags 礼品表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifinfo_PUT": (t: Gifinfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifinfo`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 若是选择卡卷 查询品牌活动接口 /api/brandactivity/queryExport 调用这个list接口 修改同理  选择id=brandActivityId
   * @name POST /admin/api/gifinfo/add
   * @summary 礼品新增
   * @tags 礼品表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifinfo/add_POST": (dto: GifinfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifinfo/add`,
      method: "POST",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/gifinfo/delete
   * @summary 礼品删除
   * @tags 礼品表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/gifinfo/delete_DELETE": (query: ApiGifinfoDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifinfo/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 礼品名称和礼品编码使用 giftName 查询
   * @name GET /admin/api/gifinfo/query
   * @summary 礼品表分页查询
   * @tags 礼品表
   * @response `200` `JsonResultPageInfoGifinfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifinfo/query_GET": (query: ApiGifinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifinfo/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoGifinfoDto>,
  /**
   * @description id 主键id必传 ,若是选择卡卷 查询品牌活动接口 /api/brandactivity/queryExport 调用这个list接口 修改同理  选择id=brandActivityId
   * @name POST /admin/api/gifinfo/update
   * @summary 礼品修改
   * @tags 礼品表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifinfo/update_POST": (dto: GifinfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifinfo/update`,
      method: "POST",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 主键id必传 giftStatus 0:禁用 1:启用
   * @name PUT /admin/api/gifinfo/updateGiftStatus
   * @summary 礼品启用/禁用状态修改
   * @tags 礼品表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifinfo/updateGiftStatus_PUT": (dto: GifinfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifinfo/updateGiftStatus`,
      method: "PUT",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/gifinfo/{id}
   * @summary 详情
   * @tags 礼品表
   * @response `200` `JsonResultGifinfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifinfo/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifinfo/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultGifinfo>,
  /**
   * No description
   * @name PUT /admin/api/gifinfo/{id}
   * @summary 修改
   * @tags 礼品表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifinfo/{id}_PUT": (id: number, t: Gifinfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifinfo/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/gifinfo/{id}
   * @summary 删除
   * @tags 礼品表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/gifinfo/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifinfo/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/gifpackage
   * @summary 新增
   * @tags 礼品套餐表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackage_POST": (t: Gifpackage, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/gifpackage
   * @summary 修改
   * @tags 礼品套餐表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackage_PUT": (t: Gifpackage, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/gifpackage/add
   * @summary 礼品套餐新增
   * @tags 礼品套餐表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackage/add_POST": (dto: GifpackageDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage/add`,
      method: "POST",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/gifpackage/delete
   * @summary 礼品套餐删除
   * @tags 礼品套餐表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/gifpackage/delete_DELETE": (query: ApiGifpackageDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 选择 礼品传入 套餐id必传 giftPackageId 数量必传 giftNum json 数组
   * @name POST /admin/api/gifpackage/packageAddGifts
   * @summary 套餐增加礼品
   * @tags 礼品套餐表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackage/packageAddGifts_POST": (gifts: GifpackageMappingDto[], options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage/packageAddGifts`,
      method: "POST",
      data: gifts,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 套餐编码 套餐名称统一用 giftPackageName 查询
   * @name GET /admin/api/gifpackage/query
   * @summary 礼品套餐表分页查询
   * @tags 礼品套餐表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackage/query_GET": (query: ApiGifpackageQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * @description 套餐id必传 giftPackageId  查询字段 输入框字段 giftName 礼品类型字段 giftType
   * @name GET /admin/api/gifpackage/queryGiftList
   * @summary 查看礼品list
   * @tags 礼品套餐表
   * @response `200` `JsonResultListGifpackageMappingDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackage/queryGiftList_GET": (query: ApiGifpackageQueryGiftListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage/queryGiftList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListGifpackageMappingDto>,
  /**
   * @description 用户列表查询 /userinfo/queryUserInfoExport 调用以上接口 若返回空则按照原型提示
   * @name POST /admin/api/gifpackage/sendPackage
   * @summary 套餐发放
   * @tags 礼品套餐表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackage/sendPackage_POST": (dto: UserGifbag, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage/sendPackage`,
      method: "POST",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/gifpackage/update
   * @summary 礼品套餐修改
   * @tags 礼品套餐表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackage/update_POST": (dto: GifpackageDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage/update`,
      method: "POST",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/gifpackage/{id}
   * @summary 详情
   * @tags 礼品套餐表
   * @response `200` `JsonResultGifpackage` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackage/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultGifpackage>,
  /**
   * No description
   * @name PUT /admin/api/gifpackage/{id}
   * @summary 修改
   * @tags 礼品套餐表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackage/{id}_PUT": (id: number, t: Gifpackage, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/gifpackage/{id}
   * @summary 删除
   * @tags 礼品套餐表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/gifpackage/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackage/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/gifpackagemapping
   * @summary 新增
   * @tags 礼品套餐表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackagemapping_POST": (t: GifpackageMapping, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackagemapping`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/gifpackagemapping
   * @summary 修改
   * @tags 礼品套餐表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackagemapping_PUT": (t: GifpackageMapping, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackagemapping`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/gifpackagemapping/query
   * @summary 礼品套餐表分页查询
   * @tags 礼品套餐表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackagemapping/query_GET": (query: ApiGifpackagemappingQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackagemapping/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/gifpackagemapping/{id}
   * @summary 详情
   * @tags 礼品套餐表
   * @response `200` `JsonResultGifpackageMapping` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackagemapping/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackagemapping/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultGifpackageMapping>,
  /**
   * No description
   * @name PUT /admin/api/gifpackagemapping/{id}
   * @summary 修改
   * @tags 礼品套餐表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/gifpackagemapping/{id}_PUT": (id: number, t: GifpackageMapping, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackagemapping/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/gifpackagemapping/{id}
   * @summary 删除
   * @tags 礼品套餐表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/gifpackagemapping/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/gifpackagemapping/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/goodarextend
   * @summary 新增
   * @tags 商品艺术扩展表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodarextend_POST": (t: GoodArextend, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodarextend`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/goodarextend
   * @summary 修改
   * @tags 商品艺术扩展表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodarextend_PUT": (t: GoodArextend, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodarextend`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/goodarextend/query
   * @summary 商品艺术扩展表分页查询
   * @tags 商品艺术扩展表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodarextend/query_GET": (query: ApiGoodarextendQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodarextend/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/goodarextend/{id}
   * @summary 详情
   * @tags 商品艺术扩展表
   * @response `200` `JsonResultGoodArextend` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodarextend/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodarextend/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultGoodArextend>,
  /**
   * No description
   * @name PUT /admin/api/goodarextend/{id}
   * @summary 修改
   * @tags 商品艺术扩展表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodarextend/{id}_PUT": (id: number, t: GoodArextend, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodarextend/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/goodarextend/{id}
   * @summary 删除
   * @tags 商品艺术扩展表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/goodarextend/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodarextend/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/goodinfo
   * @summary 新增
   * @tags 商品信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodinfo_POST": (t: GoodInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/goodinfo
   * @summary 修改
   * @tags 商品信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodinfo_PUT": (t: GoodInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/goodinfo/add
   * @summary 商品新增
   * @tags 商品信息表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodinfo/add_POST": (dto: GoodInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo/add`,
      method: "POST",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/goodinfo/delete
   * @summary 商品管理删除
   * @tags 商品信息表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/goodinfo/delete_DELETE": (query: ApiGoodinfoDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/goodinfo/importGoodList
   * @summary 商品导入
   * @tags 商品信息表
   * @response `200` `JsonResultListGoodInfoDto` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodinfo/importGoodList_POST": (file: File, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo/importGoodList`,
      method: "POST",
      data: file,
      ...options
    }) as unknown as Promise<JsonResultListGoodInfoDto>,
  /**
   * No description
   * @name GET /admin/api/goodinfo/query
   * @summary 商品信息分页查询
   * @tags 商品信息表
   * @response `200` `JsonResultPageInfoGoodInfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodinfo/query_GET": (query: ApiGoodinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoGoodInfoDto>,
  /**
   * No description
   * @name GET /admin/api/goodinfo/queryFrisclassify
   * @summary 一级分类列表查询
   * @tags 商品信息表
   * @response `200` `JsonResultListFrisclassifyDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodinfo/queryFrisclassify_GET": (query: ApiGoodinfoQueryFrisclassifyGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo/queryFrisclassify`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListFrisclassifyDto>,
  /**
   * No description
   * @name GET /admin/api/goodinfo/querySecondClassify
   * @summary 二级分类列表查询
   * @tags 商品信息表
   * @response `200` `JsonResultListSecondClassify` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodinfo/querySecondClassify_GET": (
    query: ApiGoodinfoQuerySecondClassifyGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/goodinfo/querySecondClassify`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListSecondClassify>,
  /**
   * No description
   * @name PUT /admin/api/goodinfo/update
   * @summary 商品管理修改
   * @tags 商品信息表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodinfo/update_PUT": (dto: GoodInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo/update`,
      method: "PUT",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/goodinfo/{id}
   * @summary 详情
   * @tags 商品信息表
   * @response `200` `JsonResultGoodInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodinfo/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultGoodInfo>,
  /**
   * No description
   * @name PUT /admin/api/goodinfo/{id}
   * @summary 修改
   * @tags 商品信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodinfo/{id}_PUT": (id: number, t: GoodInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/goodinfo/{id}
   * @summary 删除
   * @tags 商品信息表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/goodinfo/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/goodinfo/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/goodstatistics/queryGoodstatistics
   * @summary 商品统计分页查询
   * @tags 商品统计
   * @response `200` `JsonResultPageInfoGoodInfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodstatistics/queryGoodstatistics_GET": (
    query: ApiGoodstatisticsQueryGoodstatisticsGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/goodstatistics/queryGoodstatistics`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoGoodInfoDto>,
  /**
   * No description
   * @name GET /admin/api/goodstatistics/queryGoodstatisticsExport
   * @summary  商品统计查询导出
   * @tags 商品统计
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/goodstatistics/queryGoodstatisticsExport_GET": (
    query: ApiGoodstatisticsQueryGoodstatisticsExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/goodstatistics/queryGoodstatisticsExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name POST /admin/api/initiateinvitationlog
   * @summary 新增
   * @tags 发起邀请记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/initiateinvitationlog_POST": (t: InitiateInvitationLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/initiateinvitationlog`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/initiateinvitationlog
   * @summary 修改
   * @tags 发起邀请记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/initiateinvitationlog_PUT": (t: InitiateInvitationLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/initiateinvitationlog`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/initiateinvitationlog/query
   * @summary 发起邀请记录分页查询
   * @tags 发起邀请记录
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/initiateinvitationlog/query_GET": (query: ApiInitiateinvitationlogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/initiateinvitationlog/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/initiateinvitationlog/{id}
   * @summary 详情
   * @tags 发起邀请记录
   * @response `200` `JsonResultInitiateInvitationLog` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/initiateinvitationlog/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/initiateinvitationlog/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultInitiateInvitationLog>,
  /**
   * No description
   * @name PUT /admin/api/initiateinvitationlog/{id}
   * @summary 修改
   * @tags 发起邀请记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/initiateinvitationlog/{id}_PUT": (id: number, t: InitiateInvitationLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/initiateinvitationlog/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/initiateinvitationlog/{id}
   * @summary 删除
   * @tags 发起邀请记录
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/initiateinvitationlog/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/initiateinvitationlog/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/mulipoinitem
   * @summary 新增
   * @tags 积分明细
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipoinitem_POST": (t: MuliPoinitem, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipoinitem`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/mulipoinitem
   * @summary 修改
   * @tags 积分明细
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipoinitem_PUT": (t: MuliPoinitem, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipoinitem`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/mulipoinitem/mulipoinitemExport
   * @summary 积分明细导出
   * @tags 积分明细
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipoinitem/mulipoinitemExport_GET": (
    query: ApiMulipoinitemMulipoinitemExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/mulipoinitem/mulipoinitemExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/mulipoinitem/query
   * @summary 积分明细分页查询
   * @tags 积分明细
   * @response `200` `JsonResultPageInfoMuliPoinitemDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipoinitem/query_GET": (query: ApiMulipoinitemQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipoinitem/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMuliPoinitemDto>,
  /**
   * No description
   * @name GET /admin/api/mulipoinitem/queryHeadList
   * @summary 积分明细列表表头查询
   * @tags 积分明细
   * @response `200` `JsonResultMuliPoinitemHeadDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipoinitem/queryHeadList_GET": (query: ApiMulipoinitemQueryHeadListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipoinitem/queryHeadList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMuliPoinitemHeadDto>,
  /**
   * No description
   * @name GET /admin/api/mulipoinitem/{id}
   * @summary 详情
   * @tags 积分明细
   * @response `200` `JsonResultMuliPoinitem` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipoinitem/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipoinitem/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMuliPoinitem>,
  /**
   * No description
   * @name PUT /admin/api/mulipoinitem/{id}
   * @summary 修改
   * @tags 积分明细
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipoinitem/{id}_PUT": (id: number, t: MuliPoinitem, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipoinitem/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/mulipoinitem/{id}
   * @summary 删除
   * @tags 积分明细
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/mulipoinitem/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipoinitem/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/mulipriceactivity
   * @summary 新增
   * @tags 木里值活动表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceactivity_POST": (t: MuliPriceActivity, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceactivity`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/mulipriceactivity
   * @summary 修改
   * @tags 木里值活动表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceactivity_PUT": (t: MuliPriceActivity, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceactivity`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/mulipriceactivity/query
   * @summary 木里值活动表分页查询
   * @tags 木里值活动表
   * @response `200` `JsonResultPageInfoMuliPriceActivityDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceactivity/query_GET": (query: ApiMulipriceactivityQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceactivity/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMuliPriceActivityDto>,
  /**
   * No description
   * @name GET /admin/api/mulipriceactivity/{id}
   * @summary 详情
   * @tags 木里值活动表
   * @response `200` `JsonResultMuliPriceActivity` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceactivity/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceactivity/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMuliPriceActivity>,
  /**
   * No description
   * @name PUT /admin/api/mulipriceactivity/{id}
   * @summary 修改
   * @tags 木里值活动表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceactivity/{id}_PUT": (id: number, t: MuliPriceActivity, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceactivity/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/mulipriceactivity/{id}
   * @summary 删除
   * @tags 木里值活动表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/mulipriceactivity/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceactivity/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/mulipriceitem
   * @summary 新增
   * @tags 木里值明细
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceitem_POST": (t: MuliPriceItem, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceitem`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/mulipriceitem
   * @summary 修改
   * @tags 木里值明细
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceitem_PUT": (t: MuliPriceItem, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceitem`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/mulipriceitem/importMuliPriceSendLog
   * @summary 木里值发放
   * @tags 木里值明细
   * @response `200` `void` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceitem/importMuliPriceSendLog_POST": (muliPriceActivityId: File, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceitem/importMuliPriceSendLog`,
      method: "POST",
      data: muliPriceActivityId,
      ...options
    }) as unknown as Promise<void>,
  /**
   * @description muliPriceActivityId 木里值活动ID 必传
   * @name GET /admin/api/mulipriceitem/muliPriceSendLog
   * @summary 查看发放名单
   * @tags 木里值明细
   * @response `200` `JsonResultPageInfoMuliPriceSendLogDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceitem/muliPriceSendLog_GET": (
    query: ApiMulipriceitemMuliPriceSendLogGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/mulipriceitem/muliPriceSendLog`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMuliPriceSendLogDto>,
  /**
   * No description
   * @name GET /admin/api/mulipriceitem/muliPriceSendLogExport
   * @summary 发放名单导出
   * @tags 木里值明细
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceitem/muliPriceSendLogExport_GET": (
    query: ApiMulipriceitemMuliPriceSendLogExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/mulipriceitem/muliPriceSendLogExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/mulipriceitem/mulipriceitemExport
   * @summary 木里值明细导出
   * @tags 木里值明细
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceitem/mulipriceitemExport_GET": (
    query: ApiMulipriceitemMulipriceitemExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/mulipriceitem/mulipriceitemExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/mulipriceitem/query
   * @summary 木里值明细分页查询
   * @tags 木里值明细
   * @response `200` `JsonResultPageInfoMuliPriceItemDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceitem/query_GET": (query: ApiMulipriceitemQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceitem/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMuliPriceItemDto>,
  /**
   * No description
   * @name GET /admin/api/mulipriceitem/{id}
   * @summary 详情
   * @tags 木里值明细
   * @response `200` `JsonResultMuliPriceItem` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceitem/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceitem/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMuliPriceItem>,
  /**
   * No description
   * @name PUT /admin/api/mulipriceitem/{id}
   * @summary 修改
   * @tags 木里值明细
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipriceitem/{id}_PUT": (id: number, t: MuliPriceItem, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceitem/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/mulipriceitem/{id}
   * @summary 删除
   * @tags 木里值明细
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/mulipriceitem/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipriceitem/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/mulipricesendlog
   * @summary 新增
   * @tags 木里值发放记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipricesendlog_POST": (t: MuliPriceSendLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipricesendlog`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/mulipricesendlog
   * @summary 修改
   * @tags 木里值发放记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipricesendlog_PUT": (t: MuliPriceSendLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipricesendlog`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/mulipricesendlog/query
   * @summary 木里值发放记录分页查询
   * @tags 木里值发放记录
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipricesendlog/query_GET": (query: ApiMulipricesendlogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipricesendlog/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/mulipricesendlog/{id}
   * @summary 详情
   * @tags 木里值发放记录
   * @response `200` `JsonResultMuliPriceSendLog` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipricesendlog/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipricesendlog/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMuliPriceSendLog>,
  /**
   * No description
   * @name PUT /admin/api/mulipricesendlog/{id}
   * @summary 修改
   * @tags 木里值发放记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulipricesendlog/{id}_PUT": (id: number, t: MuliPriceSendLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipricesendlog/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/mulipricesendlog/{id}
   * @summary 删除
   * @tags 木里值发放记录
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/mulipricesendlog/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulipricesendlog/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/mulitv
   * @summary 新增
   * @tags 木里TV
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulitv_POST": (t: MuliTv, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulitv`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/mulitv
   * @summary 修改
   * @tags 木里TV
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulitv_PUT": (t: MuliTv, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulitv`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/mulitv/add
   * @summary 木里TV新增
   * @tags 木里TV
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulitv/add_POST": (dto: MuliTvDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulitv/add`,
      method: "POST",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/mulitv/delete
   * @summary 木里TV删除
   * @tags 木里TV
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/mulitv/delete_DELETE": (query: ApiMulitvDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulitv/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/mulitv/query
   * @summary 木里TV分页查询
   * @tags 木里TV
   * @response `200` `JsonResultPageInfoMuliTv` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulitv/query_GET": (query: ApiMulitvQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulitv/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMuliTv>,
  /**
   * No description
   * @name PUT /admin/api/mulitv/update
   * @summary 木里TV修改
   * @tags 木里TV
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulitv/update_PUT": (dto: MuliTvDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulitv/update`,
      method: "PUT",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/mulitv/{id}
   * @summary 详情
   * @tags 木里TV
   * @response `200` `JsonResultMuliTv` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulitv/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulitv/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMuliTv>,
  /**
   * No description
   * @name PUT /admin/api/mulitv/{id}
   * @summary 修改
   * @tags 木里TV
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/mulitv/{id}_PUT": (id: number, t: MuliTv, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulitv/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/mulitv/{id}
   * @summary 删除
   * @tags 木里TV
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/mulitv/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/mulitv/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/orderaftersale
   * @summary 新增
   * @tags 售后管理表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderaftersale_POST": (t: OrderAfterSale, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderaftersale`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/orderaftersale
   * @summary 修改
   * @tags 售后管理表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderaftersale_PUT": (t: OrderAfterSale, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderaftersale`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/orderaftersale/query
   * @summary 售后管理表分页查询
   * @tags 售后管理表
   * @response `200` `JsonResultPageInfoOrderAfterSaleDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderaftersale/query_GET": (query: ApiOrderaftersaleQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderaftersale/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderAfterSaleDto>,
  /**
   * No description
   * @name GET /admin/api/orderaftersale/{id}
   * @summary 详情
   * @tags 售后管理表
   * @response `200` `JsonResultOrderAfterSale` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderaftersale/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderaftersale/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultOrderAfterSale>,
  /**
   * No description
   * @name PUT /admin/api/orderaftersale/{id}
   * @summary 修改
   * @tags 售后管理表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderaftersale/{id}_PUT": (id: number, t: OrderAfterSale, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderaftersale/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/orderaftersale/{id}
   * @summary 删除
   * @tags 售后管理表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/orderaftersale/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderaftersale/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/ordergood
   * @summary 新增
   * @tags 订单商品表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/ordergood_POST": (t: OrderGood, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/ordergood`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/ordergood
   * @summary 修改
   * @tags 订单商品表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/ordergood_PUT": (t: OrderGood, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/ordergood`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/ordergood/query
   * @summary 订单商品表分页查询
   * @tags 订单商品表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/ordergood/query_GET": (query: ApiOrdergoodQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/ordergood/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/ordergood/{id}
   * @summary 详情
   * @tags 订单商品表
   * @response `200` `JsonResultOrderGood` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/ordergood/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/ordergood/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultOrderGood>,
  /**
   * No description
   * @name PUT /admin/api/ordergood/{id}
   * @summary 修改
   * @tags 订单商品表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/ordergood/{id}_PUT": (id: number, t: OrderGood, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/ordergood/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/ordergood/{id}
   * @summary 删除
   * @tags 订单商品表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/ordergood/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/ordergood/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/orderinfo
   * @summary 新增
   * @tags 订单信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderinfo_POST": (t: OrderInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderinfo`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/orderinfo
   * @summary 修改
   * @tags 订单信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderinfo_PUT": (t: OrderInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderinfo`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 传入订单ID
   * @name POST /admin/api/orderinfo/adminAffirmPay
   * @summary 后台确认支付
   * @tags 订单信息表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderinfo/adminAffirmPay_POST": (entity: OrderInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderinfo/adminAffirmPay`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 传入订单ID
   * @name POST /admin/api/orderinfo/adminCancelOrder
   * @summary 后台取消订单
   * @tags 订单信息表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderinfo/adminCancelOrder_POST": (entity: OrderInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderinfo/adminCancelOrder`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 传入订单ID 快递单号 快递公司
   * @name POST /admin/api/orderinfo/adminShipments
   * @summary 后台
   * @tags 订单信息表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderinfo/adminShipments_POST": (entity: OrderInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderinfo/adminShipments`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 传一个订单ID
   * @name GET /admin/api/orderinfo/orderDetail
   * @summary 订单详情
   * @tags 订单信息表
   * @response `200` `JsonResultOrderInfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderinfo/orderDetail_GET": (query: ApiOrderinfoOrderDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderinfo/orderDetail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderInfoDto>,
  /**
   * No description
   * @name GET /admin/api/orderinfo/query
   * @summary 订单信息表分页查询
   * @tags 订单信息表
   * @response `200` `JsonResultPageInfoOrderInfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderinfo/query_GET": (query: ApiOrderinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderinfo/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderInfoDto>,
  /**
   * No description
   * @name GET /admin/api/orderinfo/{id}
   * @summary 详情
   * @tags 订单信息表
   * @response `200` `JsonResultOrderInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderinfo/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderinfo/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultOrderInfo>,
  /**
   * No description
   * @name PUT /admin/api/orderinfo/{id}
   * @summary 修改
   * @tags 订单信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderinfo/{id}_PUT": (id: number, t: OrderInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderinfo/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/orderinfo/{id}
   * @summary 删除
   * @tags 订单信息表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/orderinfo/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderinfo/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/orderlogistics
   * @summary 新增
   * @tags 订单物流表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderlogistics_POST": (t: OrderLogistics, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderlogistics`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/orderlogistics
   * @summary 修改
   * @tags 订单物流表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderlogistics_PUT": (t: OrderLogistics, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderlogistics`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/orderlogistics/query
   * @summary 订单物流表分页查询
   * @tags 订单物流表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderlogistics/query_GET": (query: ApiOrderlogisticsQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderlogistics/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/orderlogistics/{id}
   * @summary 详情
   * @tags 订单物流表
   * @response `200` `JsonResultOrderLogistics` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderlogistics/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderlogistics/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultOrderLogistics>,
  /**
   * No description
   * @name PUT /admin/api/orderlogistics/{id}
   * @summary 修改
   * @tags 订单物流表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderlogistics/{id}_PUT": (id: number, t: OrderLogistics, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderlogistics/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/orderlogistics/{id}
   * @summary 删除
   * @tags 订单物流表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/orderlogistics/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderlogistics/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/orderpay
   * @summary 新增
   * @tags 订单支付表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderpay_POST": (t: OrderPay, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderpay`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/orderpay
   * @summary 修改
   * @tags 订单支付表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderpay_PUT": (t: OrderPay, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderpay`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/orderpay/query
   * @summary 订单支付表分页查询
   * @tags 订单支付表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderpay/query_GET": (query: ApiOrderpayQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderpay/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/orderpay/{id}
   * @summary 详情
   * @tags 订单支付表
   * @response `200` `JsonResultOrderPay` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderpay/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderpay/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultOrderPay>,
  /**
   * No description
   * @name PUT /admin/api/orderpay/{id}
   * @summary 修改
   * @tags 订单支付表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/orderpay/{id}_PUT": (id: number, t: OrderPay, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderpay/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/orderpay/{id}
   * @summary 删除
   * @tags 订单支付表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/orderpay/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/orderpay/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/phonebindlog
   * @summary 新增
   * @tags 合同手机号绑定记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/phonebindlog_POST": (t: PhoneBindLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/phonebindlog`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/phonebindlog
   * @summary 修改
   * @tags 合同手机号绑定记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/phonebindlog_PUT": (t: PhoneBindLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/phonebindlog`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/phonebindlog/{id}
   * @summary 详情
   * @tags 合同手机号绑定记录
   * @response `200` `JsonResultPhoneBindLog` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/phonebindlog/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/phonebindlog/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultPhoneBindLog>,
  /**
   * No description
   * @name PUT /admin/api/phonebindlog/{id}
   * @summary 修改
   * @tags 合同手机号绑定记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/phonebindlog/{id}_PUT": (id: number, t: PhoneBindLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/phonebindlog/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/phonebindlog/{id}
   * @summary 删除
   * @tags 合同手机号绑定记录
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/phonebindlog/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/phonebindlog/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/projeccontract
   * @summary 新增
   * @tags 合同管理表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projeccontract_POST": (t: Projeccontract, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projeccontract`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/projeccontract
   * @summary 修改
   * @tags 合同管理表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projeccontract_PUT": (t: Projeccontract, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projeccontract`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 传合同主键ID 字段名:id
   * @name POST /admin/api/projeccontract/contractGdApprove
   * @summary 合同高定认证
   * @tags 合同管理表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projeccontract/contractGdApprove_POST": (
    query: ApiProjeccontractContractGdApprovePostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projeccontract/contractGdApprove`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 传合同主键ID 字段名:id 项目介绍费:introduceAmount
   * @name POST /admin/api/projeccontract/createProjectIntroduceAmount
   * @summary 创建项目介绍费
   * @tags 合同管理表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projeccontract/createProjectIntroduceAmount_POST": (
    query: ApiProjeccontractCreateProjectIntroduceAmountPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projeccontract/createProjectIntroduceAmount`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/projeccontract/query
   * @summary 合同管理表分页查询
   * @tags 合同管理表
   * @response `200` `JsonResultPageInfoProjeccontractDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projeccontract/query_GET": (query: ApiProjeccontractQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projeccontract/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjeccontractDto>,
  /**
   * @description 传合同高定认证号 字段名: gdApproveNo
   * @name GET /admin/api/projeccontract/queryProjectInfoByContract
   * @summary 通过合同信息查询相关的项目详情
   * @tags 合同管理表
   * @response `200` `JsonResultPageInfoProjecinfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projeccontract/queryProjectInfoByContract_GET": (
    query: ApiProjeccontractQueryProjectInfoByContractGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projeccontract/queryProjectInfoByContract`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecinfoDto>,
  /**
   * @description 传合同高定认证号 字段名: gdApproveNo
   * @name GET /admin/api/projeccontract/queryProjectOrderInfoByContract
   * @summary 通过合同信息查询相关的订单详情
   * @tags 合同管理表
   * @response `200` `JsonResultPageInfoProjecorderDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projeccontract/queryProjectOrderInfoByContract_GET": (
    query: ApiProjeccontractQueryProjectOrderInfoByContractGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projeccontract/queryProjectOrderInfoByContract`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecorderDto>,
  /**
   * No description
   * @name POST /admin/api/projeccontract/uploadGdData
   * @summary 导入高定认证清洗数据
   * @tags 合同管理表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projeccontract/uploadGdData_POST": (multipartFile: File, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projeccontract/uploadGdData`,
      method: "POST",
      data: multipartFile,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/projeccontract/{id}
   * @summary 详情
   * @tags 合同管理表
   * @response `200` `JsonResultProjeccontract` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projeccontract/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projeccontract/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultProjeccontract>,
  /**
   * No description
   * @name PUT /admin/api/projeccontract/{id}
   * @summary 修改
   * @tags 合同管理表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projeccontract/{id}_PUT": (id: number, t: Projeccontract, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projeccontract/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/projeccontract/{id}
   * @summary 删除
   * @tags 合同管理表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/projeccontract/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projeccontract/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/projecequityinfo
   * @summary 新增
   * @tags 权益信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityinfo_POST": (t: ProjecequityInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityinfo`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/projecequityinfo
   * @summary 修改
   * @tags 权益信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityinfo_PUT": (t: ProjecequityInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityinfo`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/projecequityinfo/addProjectEquity
   * @summary 新增权益信息
   * @tags 权益信息表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityinfo/addProjectEquity_POST": (
    query: ApiProjecequityinfoAddProjectEquityPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityinfo/addProjectEquity`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/projecequityinfo/deleteProjectEquity
   * @summary 删除权益信息
   * @tags 权益信息表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/projecequityinfo/deleteProjectEquity_DELETE": (
    query: ApiProjecequityinfoDeleteProjectEquityDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityinfo/deleteProjectEquity`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/projecequityinfo/projectEquityEnable
   * @summary 权益启用/停用
   * @tags 权益信息表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityinfo/projectEquityEnable_POST": (
    query: ApiProjecequityinfoProjectEquityEnablePostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityinfo/projectEquityEnable`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/projecequityinfo/query
   * @summary 权益信息表列表
   * @tags 权益信息表
   * @response `200` `JsonResultPageInfoProjecequityInfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityinfo/query_GET": (query: ApiProjecequityinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityinfo/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecequityInfoDto>,
  /**
   * No description
   * @name GET /admin/api/projecequityinfo/queryUserProjectEquityInfo
   * @summary 权益信息下拉框
   * @tags 权益信息表
   * @response `200` `JsonResultListProjecequityInfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityinfo/queryUserProjectEquityInfo_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityinfo/queryUserProjectEquityInfo`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListProjecequityInfoDto>,
  /**
   * No description
   * @name POST /admin/api/projecequityinfo/updateProjectEquity
   * @summary 权益信息编辑
   * @tags 权益信息表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityinfo/updateProjectEquity_POST": (
    query: ApiProjecequityinfoUpdateProjectEquityPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityinfo/updateProjectEquity`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/projecequityinfo/{id}
   * @summary 详情
   * @tags 权益信息表
   * @response `200` `JsonResultProjecequityInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityinfo/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityinfo/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultProjecequityInfo>,
  /**
   * No description
   * @name PUT /admin/api/projecequityinfo/{id}
   * @summary 修改
   * @tags 权益信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityinfo/{id}_PUT": (id: number, t: ProjecequityInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityinfo/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/projecequityinfo/{id}
   * @summary 删除
   * @tags 权益信息表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/projecequityinfo/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityinfo/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/projecequityrecord
   * @summary 新增
   * @tags 权益记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord_POST": (t: ProjecequityRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityrecord`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/projecequityrecord
   * @summary 修改
   * @tags 权益记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord_PUT": (t: ProjecequityRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityrecord`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 传审批意见adminUserId 权益记录主键ID id 备注 remark
   * @name POST /admin/api/projecequityrecord/audit
   * @summary 审核
   * @tags 权益记录
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord/audit_POST": (query: ApiProjecequityrecordAuditPostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityrecord/audit`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/projecequityrecord/query
   * @summary 权益记录列表 - 总公司
   * @tags 权益记录
   * @response `200` `JsonResultPageInfoProjecequityRecordDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord/query_GET": (query: ApiProjecequityrecordQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityrecord/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecequityRecordDto>,
  /**
   * No description
   * @name GET /admin/api/projecequityrecord/queryExport
   * @summary 权益记录列表导出 - 总公司
   * @tags 权益记录
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord/queryExport_GET": (
    query: ApiProjecequityrecordQueryExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityrecord/queryExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/projecequityrecord/queryOtherExport
   * @summary 权益记录列表导出 - 分公司
   * @tags 权益记录
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord/queryOtherExport_GET": (
    query: ApiProjecequityrecordQueryOtherExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityrecord/queryOtherExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/projecequityrecord/queryOtherList
   * @summary 权益记录列表 - 分公司
   * @tags 权益记录
   * @response `200` `JsonResultPageInfoProjecequityRecordDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord/queryOtherList_GET": (
    query: ApiProjecequityrecordQueryOtherListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityrecord/queryOtherList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecequityRecordDto>,
  /**
   * @description 传一个后台用户ID adminUserId 权益记录主键ID id
   * @name POST /admin/api/projecequityrecord/updatePayUser
   * @summary 选择/更改付款人
   * @tags 权益记录
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord/updatePayUser_POST": (
    query: ApiProjecequityrecordUpdatePayUserPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityrecord/updatePayUser`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 传支付凭证 payVoucher  权益记录录主键ID id
   * @name POST /admin/api/projecequityrecord/uploadPayVoucher
   * @summary 上传支付凭证
   * @tags 权益记录
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord/uploadPayVoucher_POST": (
    query: ApiProjecequityrecordUploadPayVoucherPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityrecord/uploadPayVoucher`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 传权益记录主键ID 字段名:id
   * @name POST /admin/api/projecequityrecord/useEquityRecord
   * @summary 权益使用
   * @tags 权益记录
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord/useEquityRecord_POST": (
    query: ApiProjecequityrecordUseEquityRecordPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecequityrecord/useEquityRecord`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/projecequityrecord/{id}
   * @summary 详情
   * @tags 权益记录
   * @response `200` `JsonResultProjecequityRecord` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityrecord/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultProjecequityRecord>,
  /**
   * No description
   * @name PUT /admin/api/projecequityrecord/{id}
   * @summary 修改
   * @tags 权益记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecequityrecord/{id}_PUT": (id: number, t: ProjecequityRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityrecord/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/projecequityrecord/{id}
   * @summary 删除
   * @tags 权益记录
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/projecequityrecord/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecequityrecord/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/projecinfo
   * @summary 新增
   * @tags 项目信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfo_POST": (t: Projecinfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfo`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/projecinfo
   * @summary 修改
   * @tags 项目信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfo_PUT": (t: Projecinfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfo`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 传权益ID 权益数量 项目主键ID 字段名: equityId equitySum id
   * @name POST /admin/api/projecinfo/projectGrantEquity
   * @summary 项目发放权益
   * @tags 项目信息表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfo/projectGrantEquity_POST": (
    query: ApiProjecinfoProjectGrantEquityPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecinfo/projectGrantEquity`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/projecinfo/query
   * @summary 项目信息表分页查询
   * @tags 项目信息表
   * @response `200` `JsonResultPageInfoProjecinfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfo/query_GET": (query: ApiProjecinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfo/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecinfoDto>,
  /**
   * @description 传合同高定认证号 字段名: gdApproveNo
   * @name GET /admin/api/projecinfo/queryContractInfoByProject
   * @summary 通过项目信息查询相关的合同详情
   * @tags 项目信息表
   * @response `200` `JsonResultPageInfoProjeccontractDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfo/queryContractInfoByProject_GET": (
    query: ApiProjecinfoQueryContractInfoByProjectGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecinfo/queryContractInfoByProject`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjeccontractDto>,
  /**
   * @description 传项目主键ID 字段名: id
   * @name GET /admin/api/projecinfo/queryDetail
   * @summary 项目详情信息
   * @tags 项目信息表
   * @response `200` `JsonResultProjecinfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfo/queryDetail_GET": (query: ApiProjecinfoQueryDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfo/queryDetail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultProjecinfoDto>,
  /**
   * @description 传第三方项目编号 字段名: thirdProjectNo
   * @name GET /admin/api/projecinfo/queryProjectMaterialInfoByProject
   * @summary 通过项目信息查询相关的项目资料
   * @tags 项目信息表
   * @response `200` `JsonResultProjecmaterialDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfo/queryProjectMaterialInfoByProject_GET": (
    query: ApiProjecinfoQueryProjectMaterialInfoByProjectGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecinfo/queryProjectMaterialInfoByProject`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultProjecmaterialDto>,
  /**
   * @description 传第三方项目编号 字段名: thirdProjectNo
   * @name GET /admin/api/projecinfo/queryProjectOrderInfoByProject
   * @summary 通过项目信息查询相关的订单详情
   * @tags 项目信息表
   * @response `200` `JsonResultPageInfoProjecorderDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfo/queryProjectOrderInfoByProject_GET": (
    query: ApiProjecinfoQueryProjectOrderInfoByProjectGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecinfo/queryProjectOrderInfoByProject`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecorderDto>,
  /**
   * No description
   * @name GET /admin/api/projecinfo/{id}
   * @summary 详情
   * @tags 项目信息表
   * @response `200` `JsonResultProjecinfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfo/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfo/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultProjecinfo>,
  /**
   * No description
   * @name PUT /admin/api/projecinfo/{id}
   * @summary 修改
   * @tags 项目信息表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfo/{id}_PUT": (id: number, t: Projecinfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfo/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/projecinfo/{id}
   * @summary 删除
   * @tags 项目信息表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/projecinfo/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfo/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/projecinfoextend
   * @summary 新增
   * @tags 项目信息扩展表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfoextend_POST": (t: ProjecinfoExtend, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfoextend`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/projecinfoextend
   * @summary 修改
   * @tags 项目信息扩展表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfoextend_PUT": (t: ProjecinfoExtend, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfoextend`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/projecinfoextend/query
   * @summary 项目信息扩展表分页查询
   * @tags 项目信息扩展表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfoextend/query_GET": (query: ApiProjecinfoextendQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfoextend/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/projecinfoextend/{id}
   * @summary 详情
   * @tags 项目信息扩展表
   * @response `200` `JsonResultProjecinfoExtend` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfoextend/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfoextend/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultProjecinfoExtend>,
  /**
   * No description
   * @name PUT /admin/api/projecinfoextend/{id}
   * @summary 修改
   * @tags 项目信息扩展表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecinfoextend/{id}_PUT": (id: number, t: ProjecinfoExtend, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfoextend/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/projecinfoextend/{id}
   * @summary 删除
   * @tags 项目信息扩展表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/projecinfoextend/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecinfoextend/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/projecintroducefee
   * @summary 新增
   * @tags 介绍费表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee_POST": (t: ProjecintroduceFee, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecintroducefee`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/projecintroducefee
   * @summary 修改
   * @tags 介绍费表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee_PUT": (t: ProjecintroduceFee, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecintroducefee`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 传审核状态 0: 待上传 1:待审核  2: 已通过 3:已拒绝 auditStatus  介绍费记录主键ID id 备注 remark
   * @name POST /admin/api/projecintroducefee/audit
   * @summary 审核
   * @tags 介绍费表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee/audit_POST": (query: ApiProjecintroducefeeAuditPostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecintroducefee/audit`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/projecintroducefee/query
   * @summary 介绍费表列表 - 总公司
   * @tags 介绍费表
   * @response `200` `JsonResultPageInfoProjecintroduceFeeDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee/query_GET": (query: ApiProjecintroducefeeQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecintroducefee/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecintroduceFeeDto>,
  /**
   * No description
   * @name GET /admin/api/projecintroducefee/queryExport
   * @summary 介绍费表列表导出 - 总公司
   * @tags 介绍费表
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee/queryExport_GET": (
    query: ApiProjecintroducefeeQueryExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecintroducefee/queryExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/projecintroducefee/queryOther
   * @summary 介绍费表列表 - 分公司
   * @tags 介绍费表
   * @response `200` `JsonResultPageInfoProjecintroduceFeeDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee/queryOther_GET": (
    query: ApiProjecintroducefeeQueryOtherGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecintroducefee/queryOther`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecintroduceFeeDto>,
  /**
   * No description
   * @name GET /admin/api/projecintroducefee/queryOtherExport
   * @summary 介绍费表列表导出 - 分公司
   * @tags 介绍费表
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee/queryOtherExport_GET": (
    query: ApiProjecintroducefeeQueryOtherExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecintroducefee/queryOtherExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/projecintroducefee/queryUser
   * @summary 付款人下拉框
   * @tags 介绍费表
   * @response `200` `JsonResultListSysUserVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee/queryUser_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecintroducefee/queryUser`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysUserVo>,
  /**
   * @description 传一个后台用户ID adminUserId 介绍费记录主键ID id
   * @name POST /admin/api/projecintroducefee/updatePayUser
   * @summary 选择/更改付款人
   * @tags 介绍费表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee/updatePayUser_POST": (
    query: ApiProjecintroducefeeUpdatePayUserPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecintroducefee/updatePayUser`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 传支付凭证 payVoucher  介绍费记录主键ID id
   * @name POST /admin/api/projecintroducefee/uploadPayVoucher
   * @summary 上传支付凭证
   * @tags 介绍费表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee/uploadPayVoucher_POST": (
    query: ApiProjecintroducefeeUploadPayVoucherPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecintroducefee/uploadPayVoucher`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/projecintroducefee/{id}
   * @summary 详情
   * @tags 介绍费表
   * @response `200` `JsonResultProjecintroduceFee` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecintroducefee/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultProjecintroduceFee>,
  /**
   * No description
   * @name PUT /admin/api/projecintroducefee/{id}
   * @summary 修改
   * @tags 介绍费表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecintroducefee/{id}_PUT": (id: number, t: ProjecintroduceFee, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecintroducefee/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/projecintroducefee/{id}
   * @summary 删除
   * @tags 介绍费表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/projecintroducefee/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecintroducefee/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/projecmaterial
   * @summary 新增
   * @tags 项目资料表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecmaterial_POST": (t: Projecmaterial, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecmaterial`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/projecmaterial
   * @summary 修改
   * @tags 项目资料表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecmaterial_PUT": (t: Projecmaterial, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecmaterial`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/projecmaterial/{id}
   * @summary 详情
   * @tags 项目资料表
   * @response `200` `JsonResultProjecmaterial` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecmaterial/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecmaterial/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultProjecmaterial>,
  /**
   * No description
   * @name PUT /admin/api/projecmaterial/{id}
   * @summary 修改
   * @tags 项目资料表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecmaterial/{id}_PUT": (id: number, t: Projecmaterial, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecmaterial/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/projecmaterial/{id}
   * @summary 删除
   * @tags 项目资料表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/projecmaterial/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecmaterial/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/projecorder
   * @summary 新增
   * @tags 项目订单表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecorder_POST": (t: Projecorder, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecorder`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/projecorder
   * @summary 修改
   * @tags 项目订单表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecorder_PUT": (t: Projecorder, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecorder`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 传权益ID 权益数量 项目订单主键ID 阶段 字段名: equityId equitySum stage id
   * @name POST /admin/api/projecorder/projectGrantEquity
   * @summary 项目订单发放权益
   * @tags 项目订单表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecorder/projectGrantEquity_POST": (
    query: ApiProjecorderProjectGrantEquityPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecorder/projectGrantEquity`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/projecorder/query
   * @summary 项目订单表列表
   * @tags 项目订单表
   * @response `200` `JsonResultPageInfoProjecorderDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecorder/query_GET": (query: ApiProjecorderQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecorder/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecorderDto>,
  /**
   * @description 传项目订单主键ID 字段名: id
   * @name GET /admin/api/projecorder/queryProjectContractInfoByOrder
   * @summary 通过订单信息查询相关的合同详情
   * @tags 项目订单表
   * @response `200` `JsonResultPageInfoProjeccontractDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecorder/queryProjectContractInfoByOrder_GET": (
    query: ApiProjecorderQueryProjectContractInfoByOrderGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecorder/queryProjectContractInfoByOrder`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjeccontractDto>,
  /**
   * @description 传项目订单主键ID 字段名: id
   * @name GET /admin/api/projecorder/queryProjectInfoByOrder
   * @summary 通过订单信息查询相关的项目详情
   * @tags 项目订单表
   * @response `200` `JsonResultPageInfoProjecinfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecorder/queryProjectInfoByOrder_GET": (
    query: ApiProjecorderQueryProjectInfoByOrderGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/projecorder/queryProjectInfoByOrder`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjecinfoDto>,
  /**
   * No description
   * @name GET /admin/api/projecorder/{id}
   * @summary 详情
   * @tags 项目订单表
   * @response `200` `JsonResultProjecorder` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecorder/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecorder/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultProjecorder>,
  /**
   * No description
   * @name PUT /admin/api/projecorder/{id}
   * @summary 修改
   * @tags 项目订单表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/projecorder/{id}_PUT": (id: number, t: Projecorder, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecorder/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/projecorder/{id}
   * @summary 删除
   * @tags 项目订单表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/projecorder/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/projecorder/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/richtext
   * @summary 新增
   * @tags 富文本详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/richtext_POST": (t: RichText, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/richtext`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/richtext
   * @summary 修改
   * @tags 富文本详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/richtext_PUT": (t: RichText, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/richtext`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/richtext/query
   * @summary 富文本详情分页查询
   * @tags 富文本详情
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/richtext/query_GET": (query: ApiRichtextQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/richtext/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/richtext/{id}
   * @summary 详情
   * @tags 富文本详情
   * @response `200` `JsonResultRichText` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/richtext/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/richtext/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultRichText>,
  /**
   * No description
   * @name PUT /admin/api/richtext/{id}
   * @summary 修改
   * @tags 富文本详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/richtext/{id}_PUT": (id: number, t: RichText, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/richtext/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/richtext/{id}
   * @summary 删除
   * @tags 富文本详情
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/richtext/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/richtext/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/secondclassify
   * @summary 新增
   * @tags 子分类管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/secondclassify_POST": (t: SecondClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/secondclassify`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/secondclassify
   * @summary 修改
   * @tags 子分类管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/secondclassify_PUT": (t: SecondClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/secondclassify`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/secondclassify/add
   * @summary 二级分类管理新增
   * @tags 子分类管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/secondclassify/add_POST": (dto: SecondClassifyDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/secondclassify/add`,
      method: "POST",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/secondclassify/delete
   * @summary 二级分类管理删除
   * @tags 子分类管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/secondclassify/delete_DELETE": (query: ApiSecondclassifyDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/secondclassify/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/secondclassify/query
   * @summary 子分类管理分页查询
   * @tags 子分类管理
   * @response `200` `JsonResultPageInfoSecondClassify` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/secondclassify/query_GET": (query: ApiSecondclassifyQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/secondclassify/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSecondClassify>,
  /**
   * No description
   * @name PUT /admin/api/secondclassify/update
   * @summary 二级分类管理修改
   * @tags 子分类管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/secondclassify/update_PUT": (dto: SecondClassifyDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/secondclassify/update`,
      method: "PUT",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/secondclassify/{id}
   * @summary 详情
   * @tags 子分类管理
   * @response `200` `JsonResultSecondClassify` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/secondclassify/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/secondclassify/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultSecondClassify>,
  /**
   * No description
   * @name PUT /admin/api/secondclassify/{id}
   * @summary 修改
   * @tags 子分类管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/secondclassify/{id}_PUT": (id: number, t: SecondClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/secondclassify/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/secondclassify/{id}
   * @summary 删除
   * @tags 子分类管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/secondclassify/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/secondclassify/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/sysRole/add
   * @summary 新增角色(返回ID)
   * @tags 系统-角色表
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysRole/add_POST": (entity: SysRoleAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysRole/add`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name GET /admin/api/sysRole/allMenu
   * @summary 所有菜单模块
   * @tags 系统-角色表
   * @response `200` `JsonResultListSysMenuVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysRole/allMenu_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysRole/allMenu`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysMenuVo>,
  /**
   * No description
   * @name DELETE /admin/api/sysRole/delete
   * @summary 角色删除
   * @tags 系统-角色表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/sysRole/delete_DELETE": (query: ApiSysRoleDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysRole/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysRole/query
   * @summary 角色表分页查询
   * @tags 系统-角色表
   * @response `200` `JsonResultPageInfoSysRoleDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysRole/query_GET": (query: ApiSysRoleQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysRole/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysRoleDto>,
  /**
   * No description
   * @name GET /admin/api/sysRole/queryAll
   * @summary 角色表不分页查询
   * @tags 系统-角色表
   * @response `200` `JsonResultListSysRoleDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysRole/queryAll_GET": (query: ApiSysRoleQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysRole/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListSysRoleDto>,
  /**
   * No description
   * @name PUT /admin/api/sysRole/update
   * @summary 修改角色
   * @tags 系统-角色表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysRole/update_PUT": (entity: SysRoleAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysRole/update`,
      method: "PUT",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysStoreUserGroup/add
   * @summary 资源分组新增(返回ID)
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysStoreUserGroup/add_POST": (entity: SysStoreUserGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysStoreUserGroup/add`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysStoreUserGroup/delete
   * @summary 资源分组删除
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/sysStoreUserGroup/delete_DELETE": (query: ApiSysStoreUserGroupDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysStoreUserGroup/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysStoreUserGroup/detail
   * @summary 资源分组详情
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultSysStoreUserGroupVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysStoreUserGroup/detail_GET": (query: ApiSysStoreUserGroupDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysStoreUserGroup/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysStoreUserGroupVo>,
  /**
   * No description
   * @name GET /admin/api/sysStoreUserGroup/query
   * @summary 资源分组分页查询
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultPageInfoSysStoreUserGroupVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysStoreUserGroup/query_GET": (query: ApiSysStoreUserGroupQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysStoreUserGroup/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysStoreUserGroupVo>,
  /**
   * No description
   * @name PUT /admin/api/sysStoreUserGroup/update
   * @summary 资源分组修改
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysStoreUserGroup/update_PUT": (entity: SysStoreUserGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysStoreUserGroup/update`,
      method: "PUT",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysUser/add
   * @summary 员工新增(返回ID)
   * @tags 系统-员工管理
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysUser/add_POST": (entity: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysUser/add`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/delete
   * @summary 员工删除
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/sysUser/delete_DELETE": (query: ApiSysUserDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysUser/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysUser/query
   * @summary 员工表分页查询
   * @tags 系统-员工管理
   * @response `200` `JsonResultPageInfoSysUserVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysUser/query_GET": (query: ApiSysUserQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysUser/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysUserVo>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/resetDefault
   * @summary 重置密码
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/sysUser/resetDefault_DELETE": (query: ApiSysUserResetDefaultDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysUser/resetDefault`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/sysUser/update
   * @summary 员工修改
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysUser/update_PUT": (entity: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysUser/update`,
      method: "PUT",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysdept/add
   * @summary 部门新增(返回ID)
   * @tags 部门表
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysdept/add_POST": (entity: SysDeptAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysdept/add`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysdept/delete
   * @summary 部门删除
   * @tags 部门表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/sysdept/delete_DELETE": (query: ApiSysdeptDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysdept/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysdept/query
   * @summary 部门表分页查询
   * @tags 部门表
   * @response `200` `JsonResultPageInfoSysDeptVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysdept/query_GET": (query: ApiSysdeptQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysdept/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysDeptVo>,
  /**
   * No description
   * @name GET /admin/api/sysdept/queryAll
   * @summary 部门表不分页查询
   * @tags 部门表
   * @response `200` `JsonResultListSysDeptVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysdept/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysdept/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDeptVo>,
  /**
   * No description
   * @name GET /admin/api/sysdept/queryAllHaveUser
   * @summary 部门表不分页查询（包含员工信息）
   * @tags 部门表
   * @response `200` `JsonResultListSysDeptHaveUserVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysdept/queryAllHaveUser_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysdept/queryAllHaveUser`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDeptHaveUserVo>,
  /**
   * No description
   * @name PUT /admin/api/sysdept/update
   * @summary 部门修改
   * @tags 部门表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/sysdept/update_PUT": (entity: SysDeptAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/sysdept/update`,
      method: "PUT",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/useraddress
   * @summary 新增
   * @tags 收货地址
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/useraddress_POST": (t: UserAddress, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/useraddress`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/useraddress
   * @summary 修改
   * @tags 收货地址
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/useraddress_PUT": (t: UserAddress, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/useraddress`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/useraddress/query
   * @summary 收货地址分页查询
   * @tags 收货地址
   * @response `200` `JsonResultPageInfoUserAddress` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/useraddress/query_GET": (query: ApiUseraddressQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/useraddress/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoUserAddress>,
  /**
   * No description
   * @name GET /admin/api/useraddress/{id}
   * @summary 详情
   * @tags 收货地址
   * @response `200` `JsonResultUserAddress` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/useraddress/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/useraddress/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultUserAddress>,
  /**
   * No description
   * @name PUT /admin/api/useraddress/{id}
   * @summary 修改
   * @tags 收货地址
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/useraddress/{id}_PUT": (id: number, t: UserAddress, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/useraddress/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/useraddress/{id}
   * @summary 删除
   * @tags 收货地址
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/useraddress/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/useraddress/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/usercollect
   * @summary 新增
   * @tags 用户收藏表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usercollect_POST": (t: UserCollect, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usercollect`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/usercollect
   * @summary 修改
   * @tags 用户收藏表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usercollect_PUT": (t: UserCollect, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usercollect`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/usercollect/query
   * @summary 用户收藏表分页查询
   * @tags 用户收藏表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usercollect/query_GET": (query: ApiUsercollectQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usercollect/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/usercollect/{id}
   * @summary 详情
   * @tags 用户收藏表
   * @response `200` `JsonResultUserCollect` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usercollect/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usercollect/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultUserCollect>,
  /**
   * No description
   * @name PUT /admin/api/usercollect/{id}
   * @summary 修改
   * @tags 用户收藏表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usercollect/{id}_PUT": (id: number, t: UserCollect, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usercollect/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/usercollect/{id}
   * @summary 删除
   * @tags 用户收藏表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/usercollect/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usercollect/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/usergifbag
   * @summary 新增
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usergifbag_POST": (t: UserGifbag, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usergifbag`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/usergifbag
   * @summary 修改
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usergifbag_PUT": (t: UserGifbag, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usergifbag`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 发货
   * @name GET /admin/api/usergifbag/deliver
   * @summary 发货
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `JsonResultVoid` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usergifbag/deliver_GET": (query: ApiUsergifbagDeliverGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usergifbag/deliver`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/usergifbag/queryConvertRecord
   * @summary 兑换记录
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `JsonResultPageInfoUserGifbagDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usergifbag/queryConvertRecord_GET": (
    query: ApiUsergifbagQueryConvertRecordGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/usergifbag/queryConvertRecord`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoUserGifbagDto>,
  /**
   * No description
   * @name GET /admin/api/usergifbag/queryConvertRecordExport
   * @summary 兑换记录导出
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usergifbag/queryConvertRecordExport_GET": (
    query: ApiUsergifbagQueryConvertRecordExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/usergifbag/queryConvertRecordExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * @description 输入框查询字段 userNo
   * @name GET /admin/api/usergifbag/queryGiftDetail
   * @summary 查看礼包详情
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `JsonResultListUserGifbagRecordDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usergifbag/queryGiftDetail_GET": (query: ApiUsergifbagQueryGiftDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usergifbag/queryGiftDetail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListUserGifbagRecordDto>,
  /**
   * @description 输入框查询字段 userNo
   * @name GET /admin/api/usergifbag/querySendRecord
   * @summary 发放记录
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `JsonResultPageInfoUserGifbagDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usergifbag/querySendRecord_GET": (query: ApiUsergifbagQuerySendRecordGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usergifbag/querySendRecord`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoUserGifbagDto>,
  /**
   * No description
   * @name GET /admin/api/usergifbag/querySendRecordExport
   * @summary 发放记录导出
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usergifbag/querySendRecordExport_GET": (
    query: ApiUsergifbagQuerySendRecordExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/usergifbag/querySendRecordExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/usergifbag/{id}
   * @summary 详情
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `JsonResultUserGifbag` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usergifbag/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usergifbag/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultUserGifbag>,
  /**
   * No description
   * @name PUT /admin/api/usergifbag/{id}
   * @summary 修改
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/usergifbag/{id}_PUT": (id: number, t: UserGifbag, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usergifbag/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/usergifbag/{id}
   * @summary 删除
   * @tags 用户礼包表-礼包套餐实体-兑换记录-发放记录
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/usergifbag/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/usergifbag/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/userinfo
   * @summary 新增
   * @tags 会员管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinfo_POST": (t: UserInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinfo`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/userinfo
   * @summary 修改
   * @tags 会员管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinfo_PUT": (t: UserInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinfo`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description nickname 输入框查询使用这个字段传值
   * @name GET /admin/api/userinfo/query
   * @summary 会员管理分页查询
   * @tags 会员管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinfo/query_GET": (query: ApiUserinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinfo/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * @description userNO 用户编号传值
   * @name GET /admin/api/userinfo/queryUserAddress
   * @summary 收货地址查询
   * @tags 会员管理
   * @response `200` `JsonResultListUserAddress` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinfo/queryUserAddress_GET": (query: ApiUserinfoQueryUserAddressGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinfo/queryUserAddress`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListUserAddress>,
  /**
   * @description nickname 输入框查询使用这个字段传值
   * @name GET /admin/api/userinfo/queryUserInfoExport
   * @summary 会员管理信息导出
   * @tags 会员管理
   * @response `200` `JsonResultListUserInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinfo/queryUserInfoExport_GET": (
    query: ApiUserinfoQueryUserInfoExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/userinfo/queryUserInfoExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListUserInfo>,
  /**
   * @description userNO 用户编号传值
   * @name POST /admin/api/userinfo/setBlackGoldMember
   * @summary 设置黑金会员
   * @tags 会员管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinfo/setBlackGoldMember_POST": (
    query: ApiUserinfoSetBlackGoldMemberPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/userinfo/setBlackGoldMember`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description ids：主键id逗号分隔 ,isEmployee: 是否是员工 0否 1是
   * @name POST /admin/api/userinfo/updateBatchEmployeeStatus
   * @summary  批量操作 成为员工/不是员工
   * @tags 会员管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinfo/updateBatchEmployeeStatus_POST": (entity: UserInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinfo/updateBatchEmployeeStatus`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/userinfo/{id}
   * @summary 详情
   * @tags 会员管理
   * @response `200` `JsonResultUserInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinfo/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinfo/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultUserInfo>,
  /**
   * No description
   * @name PUT /admin/api/userinfo/{id}
   * @summary 修改
   * @tags 会员管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinfo/{id}_PUT": (id: number, t: UserInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinfo/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/userinfo/{id}
   * @summary 删除
   * @tags 会员管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/userinfo/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinfo/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/userinvitedrecord
   * @summary 新增
   * @tags 用户邀请记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinvitedrecord_POST": (t: UserInvitedRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinvitedrecord`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/userinvitedrecord
   * @summary 修改
   * @tags 用户邀请记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinvitedrecord_PUT": (t: UserInvitedRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinvitedrecord`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 绑定时间就是创建时间 模糊查询搜索框inviterUserNo字段
   * @name GET /admin/api/userinvitedrecord/query
   * @summary 用户邀请记录分页查询
   * @tags 用户邀请记录
   * @response `200` `JsonResultPageInfoUserInvitedRecordDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinvitedrecord/query_GET": (query: ApiUserinvitedrecordQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinvitedrecord/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoUserInvitedRecordDto>,
  /**
   * No description
   * @name GET /admin/api/userinvitedrecord/userinvitedrecordExport
   * @summary 邀请记录导出
   * @tags 用户邀请记录
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinvitedrecord/userinvitedrecordExport_GET": (
    query: ApiUserinvitedrecordUserinvitedrecordExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/userinvitedrecord/userinvitedrecordExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/userinvitedrecord/{id}
   * @summary 详情
   * @tags 用户邀请记录
   * @response `200` `JsonResultUserInvitedRecord` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinvitedrecord/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinvitedrecord/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultUserInvitedRecord>,
  /**
   * No description
   * @name PUT /admin/api/userinvitedrecord/{id}
   * @summary 修改
   * @tags 用户邀请记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userinvitedrecord/{id}_PUT": (id: number, t: UserInvitedRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinvitedrecord/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/userinvitedrecord/{id}
   * @summary 删除
   * @tags 用户邀请记录
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/userinvitedrecord/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userinvitedrecord/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/userlevellog
   * @summary 新增
   * @tags 会员等级变更记录管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userlevellog_POST": (t: UserLevelLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userlevellog`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/userlevellog
   * @summary 修改
   * @tags 会员等级变更记录管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userlevellog_PUT": (t: UserLevelLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userlevellog`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/userlevellog/query
   * @summary 会员等级变更记录管理分页查询
   * @tags 会员等级变更记录管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userlevellog/query_GET": (query: ApiUserlevellogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userlevellog/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/userlevellog/{id}
   * @summary 详情
   * @tags 会员等级变更记录管理
   * @response `200` `JsonResultUserLevelLog` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userlevellog/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userlevellog/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultUserLevelLog>,
  /**
   * No description
   * @name PUT /admin/api/userlevellog/{id}
   * @summary 修改
   * @tags 会员等级变更记录管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userlevellog/{id}_PUT": (id: number, t: UserLevelLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userlevellog/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/userlevellog/{id}
   * @summary 删除
   * @tags 会员等级变更记录管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/userlevellog/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userlevellog/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/userssign
   * @summary 新增
   * @tags 用户签到
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userssign_POST": (t: UsersSign, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userssign`,
      method: "POST",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/userssign
   * @summary 修改
   * @tags 用户签到
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userssign_PUT": (t: UsersSign, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userssign`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/userssign/query
   * @summary 用户签到分页查询
   * @tags 用户签到
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userssign/query_GET": (query: ApiUserssignQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userssign/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/userssign/{id}
   * @summary 详情
   * @tags 用户签到
   * @response `200` `JsonResultUsersSign` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userssign/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userssign/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultUsersSign>,
  /**
   * No description
   * @name PUT /admin/api/userssign/{id}
   * @summary 修改
   * @tags 用户签到
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userssign/{id}_PUT": (id: number, t: UsersSign, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userssign/${id}`,
      method: "PUT",
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/userssign/{id}
   * @summary 删除
   * @tags 用户签到
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "api/userssign/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `api/userssign/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/userstatistics/queryUserStatistics
   * @summary 用户统计分页查询
   * @tags 用户统计
   * @response `200` `JsonResultPageInfoOrderInfoDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userstatistics/queryUserStatistics_GET": (
    query: ApiUserstatisticsQueryUserStatisticsGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/userstatistics/queryUserStatistics`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderInfoDto>,
  /**
   * No description
   * @name GET /admin/api/userstatistics/queryUserStatisticsExport
   * @summary 用户统计查询导出
   * @tags 用户统计
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "api/userstatistics/queryUserStatisticsExport_GET": (
    query: ApiUserstatisticsQueryUserStatisticsExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `api/userstatistics/queryUserStatisticsExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name POST /admin/auth/forgot
   * @summary 忘记密码
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "auth/forgot_POST": (loginDto: RegisterDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `auth/forgot`,
      method: "POST",
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/auth/login
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "auth/login_POST": (loginDto: LoginDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `auth/login`,
      method: "POST",
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/auth/query
   * @summary 获取当前用户权限
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultSysAuthVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "auth/query_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `auth/query`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultSysAuthVo>,
  /**
   * No description
   * @name POST /admin/auth/register
   * @summary 注册（返回token）
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "auth/register_POST": (loginDto: RegisterDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `auth/register`,
      method: "POST",
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/auth/resetPwd
   * @summary 修改密码
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "auth/resetPwd_PUT": (resetPwdParam: ResetPwdParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `auth/resetPwd`,
      method: "PUT",
      data: resetPwdParam,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/common/muliCommon/info
   * @summary 腾讯云存储信息
   * @tags 后台公用controller
   * @response `200` `JsonResultMapStringObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "common/muliCommon/info_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `common/muliCommon/info`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMapStringObject>,
  /**
   * No description
   * @name POST /admin/shareaccount/add
   * @summary 分享逍客主账号增加
   * @tags 分享逍客主账号
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "shareaccount/add_POST": (shareAccount: ShareAccount, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccount/add`,
      method: "POST",
      data: shareAccount,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/shareaccount/query
   * @summary 分销逍客主账号明细列表分页查询
   * @tags 分享逍客主账号
   * @response `200` `JsonResultPageInfoShareAccount` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "shareaccount/query_GET": (query: ShareaccountQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccount/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoShareAccount>,
  /**
   * No description
   * @name GET /admin/shareaccount/queryListExport
   * @summary 纷享销客账号导出
   * @tags 分享逍客主账号
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "shareaccount/queryListExport_GET": (query: ShareaccountQueryListExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccount/queryListExport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name PUT /admin/shareaccount/update
   * @summary 分享逍客主账号修改
   * @tags 分享逍客主账号
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "shareaccount/update_PUT": (shareAccount: ShareAccount, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccount/update`,
      method: "PUT",
      data: shareAccount,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/shareaccount/{id}
   * @summary 分享逍客主账号通过Id查询详情
   * @tags 分享逍客主账号
   * @response `200` `ShareAccount` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "shareaccount/{id}_GET": (id: object, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccount/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<ShareAccount>,
  /**
   * No description
   * @name DELETE /admin/shareaccount/{id}
   * @summary 分享逍客主账号删除
   * @tags 分享逍客主账号
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "shareaccount/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccount/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/shareaccountcontact/add
   * @summary 分享逍客子账号增加
   * @tags 分享逍客子账号
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "shareaccountcontact/add_POST": (account: ShareAccountContact, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccountcontact/add`,
      method: "POST",
      data: account,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/shareaccountcontact/query
   * @summary 分销逍客子账号明细列表分页查询
   * @tags 分享逍客子账号
   * @response `200` `JsonResultPageInfoShareAccountContact` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "shareaccountcontact/query_GET": (query: ShareaccountcontactQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccountcontact/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoShareAccountContact>,
  /**
   * No description
   * @name PUT /admin/shareaccountcontact/update
   * @summary 分享逍客子账号修改
   * @tags 分享逍客子账号
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "shareaccountcontact/update_PUT": (account: ShareAccountContact, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccountcontact/update`,
      method: "PUT",
      data: account,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/shareaccountcontact/{id}
   * @summary 分享逍客子账号通过Id查询详情
   * @tags 分享逍客子账号
   * @response `200` `ShareAccountContact` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "shareaccountcontact/{id}_GET": (id: object, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccountcontact/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<ShareAccountContact>,
  /**
   * No description
   * @name DELETE /admin/shareaccountcontact/{id}
   * @summary 分享逍客子账号删除
   * @tags 分享逍客子账号
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "shareaccountcontact/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `shareaccountcontact/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/weimob/auth/code
   * @summary acceptCode
   * @tags weimob-auth-controller
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "weimob/auth/code_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `weimob/auth/code`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<void>
};
