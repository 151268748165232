export enum EAuthCode {
  /** 系统管理 */
  SYSTEM_MANAGEMENT = 'system-management',
  /** 系统管理-用户管理 */
  USER_MANAGEMENT = 'user-management',
  /** 系统管理-用户管理-添加用户 */
  USER_BUTTON = 'user-button',
  /** 系统管理-用户管理-搜索用户 */
  USER_BUTTON = 'user-button',
  /** 系统管理-用户管理-编辑用户 */
  USER_BUTTON = 'user-button',
  /** 系统管理-用户管理-删除用户 */
  USER_BUTTON = 'user-button',
  /** 系统管理-用户管理-重置密码 */
  USER_BUTTON = 'user-button',

  /** 系统管理-角色管理 */
  ROLE_MANAGEMENT = 'role-management',
  /** 系统管理-角色管理-新增角色 */
  ROLE_BUTTON = 'role-button',
  /** 系统管理-角色管理-搜索角色 */
  ROLE_BUTTON = 'role-button',
  /** 系统管理-角色管理-编辑用户 */
  ROLE_BUTTON = 'role-button',
  /** 系统管理-角色管理-删除用户 */
  ROLE_BUTTON = 'role-button',

  /** 系统管理-纷享销客账号管理 */
  SHARED_ACCOUNT_MANAGEMENT = 'shared-account-management',
  /** 系统管理-纷享销客账号管理-纷享销客子账号管理 */
  SHARED_ACCOUNT_CHILDREN_MANAGEMENT = 'shared-account-children-management',
  /** 系统管理-纷享销客账号管理-纷享销客子账号管理-新增角色 */
  ROLE_BUTTON = 'role-button',

  /** 会员管理 */
  MEMBER_MANAGEMENT = 'member-management',
  /** 会员管理-会员列表 */
  MEMBER_LIST = 'member-list',
  /** 会员管理-会员列表-搜索会员 */
  MEMBER_BUTTON = 'member-button',
  /** 会员管理-会员列表-批量成为员工 */
  MEMBER_BUTTON = 'member-button',
  /** 会员管理-会员列表-批量撤销员工 */
  MEMBER_BUTTON = 'member-button',
  /** 会员管理-会员列表-设置黑金会员 */
  MEMBER_BUTTON = 'member-button',
  /** 会员管理-会员列表-查看收货地址 */
  MEMBER_BUTTON = 'member-button',

  /** 邀请有礼活动管理 */
  INVITE_MANAGEMENT = 'invite-management',
  /** 邀请有礼活动管理-基本设置 */
  INVITE_BASIC_SETTING = 'invite-basic-setting',
  /** 邀请有礼活动管理-基本设置-保存设置 */
  INVITE_BASIC_BUTTON = 'invite-basic-button',

  /** 邀请有礼活动管理-礼品列表 */
  GIFT_LIST = 'gift-list',
  /** 邀请有礼活动管理-礼品列表-新增礼品 */
  GIFT_LIST_BUTTON = 'gift-list-button',
  /** 邀请有礼活动管理-礼品列表-搜索礼品 */
  GIFT_LIST_BUTTON = 'gift-list-button',
  /** 邀请有礼活动管理-礼品列表-编辑礼品 */
  GIFT_LIST_BUTTON = 'gift-list-button',
  /** 邀请有礼活动管理-礼品列表-删除礼品 */
  GIFT_LIST_BUTTON = 'gift-list-button',
  /** 邀请有礼活动管理-礼品列表-禁用礼品 */
  GIFT_LIST_BUTTON = 'gift-list-button',
  /** 邀请有礼活动管理-礼品列表-启用礼品 */
  GIFT_LIST_BUTTON = 'gift-list-button',

  /** 邀请有礼活动管理-套餐列表 */
  PACKAGE_LIST = 'package-list',
  /** 邀请有礼活动管理-套餐列表-新增套餐 */
  PACKAGE_LIST_BUTTON = 'package-list-button',
  /** 邀请有礼活动管理-套餐列表-搜索套餐 */
  PACKAGE_LIST_BUTTON = 'package-list-button',
  /** 邀请有礼活动管理-套餐列表-编辑套餐 */
  PACKAGE_LIST_BUTTON = 'package-list-button',
  /** 邀请有礼活动管理-套餐列表-删除套餐 */
  PACKAGE_LIST_BUTTON = 'package-list-button',
  /** 邀请有礼活动管理-套餐列表-选择礼品 */
  PACKAGE_LIST_BUTTON = 'package-list-button',
  /** 邀请有礼活动管理-套餐列表-发放套餐 */
  PACKAGE_LIST_BUTTON = 'package-list-button',

  /** 邀请有礼活动管理-邀请记录 */
  INVITE_RECORD = 'invite-record',
  /** 邀请有礼活动管理-邀请记录-导出邀请记录 */
  INVITE_RECORD_BUTTON = 'invite-record-button',
  /** 邀请有礼活动管理-邀请记录-搜索邀请记录 */
  INVITE_RECORD_BUTTON = 'invite-record-button',

  /** 邀请有礼活动管理-兑换记录 */
  EXCHANGE_RECORD = 'exchange-record',
  /** 邀请有礼活动管理-兑换记录-导出兑换记录 */
  EXCHANGE_RECORD_BUTTON = 'exchange-record-button',
  /** 邀请有礼活动管理-兑换记录-搜索兑换记录 */
  EXCHANGE_RECORD_BUTTON = 'exchange-record-button',
  /** 邀请有礼活动管理-兑换记录-查看礼包详情 */
  EXCHANGE_RECORD_BUTTON = 'exchange-record-button',
  /** 邀请有礼活动管理-兑换记录-发货 */
  EXCHANGE_RECORD_BUTTON = 'exchange-record-button',
  /** 邀请有礼活动管理-兑换记录-发货详情 */
  EXCHANGE_RECORD_BUTTON = 'exchange-record-button',

  /** 邀请有礼活动管理-发放记录 */
  ISSUE_RECORD = 'issue-record',
  /** 邀请有礼活动管理-发放记录-导出发放记录 */
  ISSUE_RECORD_BUTTON = 'issue-record-button',
  /** 邀请有礼活动管理-发放记录-搜索发放记录 */
  ISSUE_RECORD_BUTTON = 'issue-record-button',
  /** 邀请有礼活动管理-发放记录-查看礼包详情 */
  ISSUE_RECORD_BUTTON = 'issue-record-button',
  /** 邀请有礼活动管理-发放记录-发货 */
  ISSUE_RECORD_BUTTON = 'issue-record-button',
  /** 邀请有礼活动管理-发放记录-发货详情 */
  ISSUE_RECORD_BUTTON = 'issue-record-button',

  /** 积分管理 */
  INTEGRAL_MANAGEMENT = 'integral-management',
  /** 积分管理-积分设置 */
  INTEGRAL_SETTING = 'integral-setting',
  /** 积分管理-积分设置-保存设置1 */
  INTEGRAL_SETTING_BUTTON = 'integral-setting-button',
  /** 积分管理-积分设置-保存设置2 */
  INTEGRAL_SETTING_BUTTON = 'integral-setting-button',
  /** 积分管理-积分设置-保存设置3 */
  INTEGRAL_SETTING_BUTTON = 'integral-setting-button',
  /** 积分管理-积分设置-保存设置4 */
  INTEGRAL_SETTING_BUTTON = 'integral-setting-button',
  /** 积分管理-积分设置-保存设置5 */
  INTEGRAL_SETTING_BUTTON = 'integral-setting-button',

  /** 积分管理-积分明细 */
  INTEGRAL_DETAILS = 'integral-details',
  /** 积分管理-积分明细-搜索积分明细 */
  INTEGRAL_DETAILS_BUTTON = 'integral-details-button',
  /** 积分管理-积分明细-导出积分明细 */
  INTEGRAL_DETAILS_BUTTON = 'integral-details-button',

  /** 木里值管理 */
  MULI_MANAGEMENT = 'muli-management',
  /** 木里值管理-木里值设置 */
  MULI_SETTING = 'muli-setting',
  /** 木里值管理-木里值设置-保存设置1 */
  MULI_SETTING_BUTTON = 'muli-setting-button',
  /** 木里值管理-木里值设置-保存设置2 */
  MULI_SETTING_BUTTON = 'muli-setting-button',
  /** 木里值管理-木里值设置-保存设置3 */
  MULI_SETTING_BUTTON = 'muli-setting-button',
  /** 木里值管理-木里值设置-保存设置4 */
  MULI_SETTING_BUTTON = 'muli-setting-button',
  /** 木里值管理-木里值设置-保存设置5 */
  MULI_SETTING_BUTTON = 'muli-setting-button',
  /** 木里值管理-木里值设置-保存设置6 */
  MULI_SETTING_BUTTON = 'muli-setting-button',

  /** 木里值管理-木里值明细 */
  MULI_DETAILS = 'muli-details',
  /** 木里值管理-木里值明细-搜索木里值明细 */
  MULI_DETAILS_BUTTON = 'muli-details-button',
  /** 木里值管理-木里值明细-导出木里值明细 */
  MULI_DETAILS_BUTTON = 'muli-details-button',

  /** 木里值管理-参加活动发放木里值 */
  ACTIVITY_ISSUE_LIST = 'activity-issue-list',
  /** 木里值管理-参加活动发放木里值-搜索活动 */
  ACTIVITY_ISSUE_LIST_BUTTON = 'activity-issue-list-button',
  /** 木里值管理-参加活动发放木里值-新建活动 */
  ACTIVITY_ISSUE_LIST_BUTTON = 'activity-issue-list-button',
  /** 木里值管理-参加活动发放木里值-发放 */
  ACTIVITY_ISSUE_LIST_BUTTON = 'activity-issue-list-button',
  /** 木里值管理-参加活动发放木里值-查看发放名单 */
  ACTIVITY_ISSUE_LIST_BUTTON = 'activity-issue-list-button',

  /** 内容管理 */
  CONTENT_MANAGEMENT = 'content-management',
  /** 内容管理-首页banner管理 */
  BANNER_MANAGEMENT = 'banner-management',
  /** 内容管理-首页banner管理-新增banner */
  BANNER_MANAGEMENT_BUTTON = 'banner-management-button',
  /** 内容管理-首页banner管理-批量启用 */
  BANNER_MANAGEMENT_BUTTON = 'banner-management-button',
  /** 内容管理-首页banner管理-批量禁用 */
  BANNER_MANAGEMENT_BUTTON = 'banner-management-button',
  /** 内容管理-首页banner管理-编辑banner */
  BANNER_MANAGEMENT_BUTTON = 'banner-management-button',
  /** 内容管理-首页banner管理-删除banner */
  BANNER_MANAGEMENT_BUTTON = 'banner-management-button',
  /** 内容管理-首页banner管理-启用banner */
  BANNER_MANAGEMENT_BUTTON = 'banner-management-button',
  /** 内容管理-首页banner管理-禁用banner */
  BANNER_MANAGEMENT_BUTTON = 'banner-management-button',

  /** 内容管理-高定礼遇介绍 */
  COURTESY_INTRODUCE = 'courtesy-introduce',
  /** 内容管理-高定礼遇介绍-保存高定礼遇介绍 */
  COURTESY_INTRODUCE_BUTTON = 'courtesy-introduce-button',

  /** 内容管理-会员权益 */
  VIP_RIGHTS_RULES = 'vip-rights-rules',
  /** 内容管理-会员权益-保存会员权益 */
  VIP_RIGHTS_RULES_BUTTON = 'vip-rights-rules-button',

  /** 内容管理-关于木里 */
  ABOUT_MULI = 'about-muli',
  /** 内容管理-关于木里-保存关于木里 */
  ABOUT_MULI_BUTTON = 'about-muli-button',

  /** 内容管理-分享设置 */
  SHARE_SETTING = 'share-setting',
  /** 内容管理-分享设置-保存分享设置 */
  SHARE_SETTING_BUTTON = 'share-setting-button',

  /** 内容管理-木里TV */
  MULI_TV = 'muli-tv',
  /** 内容管理-木里TV-新增视频 */
  MULI_TV_BUTTON = 'muli-tv-button',
  /** 内容管理-木里TV-编辑视频 */
  MULI_TV_BUTTON = 'muli-tv-button',
  /** 内容管理-木里TV-删除视频 */
  MULI_TV_BUTTON = 'muli-tv-button',

  /** 线下品牌活动管理 */
  BRAND_ACTIVITY_MANAGEMENT = 'brand-activity-management',
  /** 线下品牌活动管理-线下品牌活动管理 */
  ACTIVITY_MANAGEMENT = 'activity-management',
  /** 线下品牌活动管理-线下品牌活动管理-新增品牌活动 */
  ACTIVITY_MANAGEMENT_BUTTON = 'activity-management-button',
  /** 线下品牌活动管理-线下品牌活动管理-搜索品牌活动 */
  ACTIVITY_MANAGEMENT_BUTTON = 'activity-management-button',
  /** 线下品牌活动管理-线下品牌活动管理-编辑品牌活动 */
  ACTIVITY_MANAGEMENT_BUTTON = 'activity-management-button',
  /** 线下品牌活动管理-线下品牌活动管理-删除品牌活动 */
  ACTIVITY_MANAGEMENT_BUTTON = 'activity-management-button',
  /** 线下品牌活动管理-线下品牌活动管理-查看报名名单 */
  ACTIVITY_MANAGEMENT_BUTTON = 'activity-management-button',

  /** 积分商城管理 */
  STORE_MANAGEMENT = 'store-management',
  /** 积分商城管理-分类管理 */
  CLASSIFY_MANAGEMENT = 'classify-management',
  /** 积分商城管理-分类管理-新增分类 */
  CLASSIFY_MANAGEMENT_BUTTON = 'classify-management-button',
  /** 积分商城管理-分类管理-子分类 */
  CLASSIFY_MANAGEMENT_BUTTON = 'classify-management-button',
  /** 积分商城管理-分类管理-编辑分类 */
  CLASSIFY_MANAGEMENT_BUTTON = 'classify-management-button',
  /** 积分商城管理-分类管理-删除分类 */
  CLASSIFY_MANAGEMENT_BUTTON = 'classify-management-button',

  /** 积分商城管理-子分类管理 */
  SUBCLASSIFY_MANAGEMENT = 'subclassify-management',
  /** 积分商城管理-子分类管理-新增子分类 */
  SUBCLASSIFY_MANAGEMENT_BUTTON = 'subclassify-management-button',
  /** 积分商城管理-子分类管理-编辑子分类 */
  SUBCLASSIFY_MANAGEMENT_BUTTON = 'subclassify-management-button',
  /** 积分商城管理-子分类管理-删除子分类 */
  SUBCLASSIFY_MANAGEMENT_BUTTON = 'subclassify-management-button',

  /** 积分商城管理-商品管理 */
  GOOD_MANAGEMENT = 'good-management',
  /** 积分商城管理-商品管理-新增实物商品 */
  GOOD_MANAGEMENT_BUTTON = 'good-management-button',
  /** 积分商城管理-商品管理-新增艺术品商品 */
  GOOD_MANAGEMENT_BUTTON = 'good-management-button',
  /** 积分商城管理-商品管理-新增门票商品 */
  GOOD_MANAGEMENT_BUTTON = 'good-management-button',
  /** 积分商城管理-商品管理-搜索商品 */
  GOOD_MANAGEMENT_BUTTON = 'good-management-button',
  /** 积分商城管理-商品管理-导入商品 */
  GOOD_MANAGEMENT_BUTTON = 'good-management-button',
  /** 积分商城管理-商品管理-编辑商品 */
  GOOD_MANAGEMENT_BUTTON = 'good-management-button',
  /** 积分商城管理-商品管理-删除商品 */
  GOOD_MANAGEMENT_BUTTON = 'good-management-button',

  /** 积分商城管理-购买须知 */
  BUY_INFO = 'buy-info',
  /** 积分商城管理-购买须知-保存购买须知 */
  BUY_INFO_BUTTON = 'buy-info-button',

  /** 积分商城管理-转账管理 */
  TRANSFER_MANAGEMENT = 'transfer-management',
  /** 积分商城管理-转账管理-转账管理 */
  TRANSFER_MANAGEMENT_BUTTON = 'transfer-management-button',

  /** 积分商城管理-新增艺术品 */
  ADD_ARTWORK = 'add-artwork',
  /** 积分商城管理-新增艺术品-保存新增艺术品 */
  ADD_ARTWORK_BUTTON = 'add-artwork-button',

  /** 积分商城管理-新增门票 */
  ADD_TICKET = 'add-ticket',
  /** 积分商城管理-新增门票-保存新增门票 */
  ADD_TICKET_BUTTON = 'add-ticket-button',

  /** 积分商城管理-新增实物 */
  ADD_ITEM = 'add-item',
  /** 积分商城管理-新增实物-保存新增实物 */
  ADD_ITEM_BUTTON = 'add-item-button',

  /** 订单管理 */
  ORDER_MANAGEMENT = 'order-management',
  /** 订单管理-订单管理列表 */
  ORDER_LIST_MANAGEMENT = 'order-list-management',
  /** 订单管理-订单管理列表-搜索订单 */
  ORDER_MANAGEMENT_BUTTON = 'order-management-button',
  /** 订单管理-订单管理列表-查看订单详情 */
  ORDER_MANAGEMENT_BUTTON = 'order-management-button',
  /** 订单管理-订单管理列表-订单发货 */
  ORDER_MANAGEMENT_BUTTON = 'order-management-button',
  /** 订单管理-订单管理列表-确认支付 */
  ORDER_MANAGEMENT_BUTTON = 'order-management-button',
  /** 订单管理-订单管理列表-取消订单 */
  ORDER_MANAGEMENT_BUTTON = 'order-management-button',

  /** 订单管理-订单详情 */
  ORDER_DETAIL_MANAGEMENT = 'order-detail-management',

  /** 订单管理-售后管理 */
  AFTER_SALE_MANAGEMENT = 'after-sale-management',
  /** 订单管理-售后管理-搜索售后订单 */
  AFTER_SALE_MANAGEMENT_BUTTON = 'after-sale-management-button',

  /** 报表统计 */
  REPORT_MANAGEMENT = 'report-management',
  /** 报表统计-用户消费统计 */
  USER_CONSUME = 'user-consume',
  /** 报表统计-用户消费统计-搜索用户消费记录 */
  USER_CONSUME_BUTTON = 'user-consume-button',
  /** 报表统计-用户消费统计-导出用户消费列表 */
  USER_CONSUME_BUTTON = 'user-consume-button',

  /** 报表统计-商品销售统计 */
  GOOD_SALES = 'good-sales',
  /** 报表统计-商品销售统计-搜索商品销售记录 */
  GOOD_SALES_BUTTON = 'good-sales-button',
  /** 报表统计-商品销售统计-导出商品销售列表 */
  GOOD_SALES_BUTTON = 'good-sales-button',

  /** 项目管理 */
  PROJECT_MANAGEMENT = 'project-management',
  /** 项目管理-合同列表 */
  CONTRACT_MANAGEMENT = 'contract-management',
  /** 项目管理-合同列表-按钮 */
  CONTRACT_MANAGEMENT_BUTTON = 'contract-management-button',

  /** 项目管理-介绍费记录-总公司 */
  INTRODUCE_COST_RECORD_PARENT_COMPANY = 'introduce-cost-record-parent-company',
  /** 项目管理-介绍费记录-总公司-按钮 */
  INTRODUCE_COST_RECORD_PARENT_COMPANY_MANAGEMENT_BUTTON = 'introduce-cost-record-parent-company-management-button',

  /** 项目管理-介绍费记录-子公司 */
  INTRODUCE_COST_RECORD_SUBSIDIARY = 'introduce-cost-record-subsidiary',
  /** 项目管理-介绍费记录-子公司-按钮 */
  INTRODUCE_COST_RECORD_SUBSIDIARY_BUTTON = 'introduce-cost-record-subsidiary-button',

  /** 项目管理-项目管理 */
  PROJECT_LIST_MANAGEMENT = 'project-list-management',
  /** 项目管理-项目管理-按钮 */
  PROJECT_LIST_MANAGEMENT_BUTTON = 'project-list-management-button',

  /** 项目管理-项目阶段详情 */
  PROJECT_DATA = 'project-data',
  /** 项目管理-项目阶段详情-按钮 */
  PROJECT_DATA_BUTTON = 'project-data-button',

  /** 项目管理-权益管理 */
  RIGHTS_MANAGEMENT = 'rights-management',
  /** 项目管理-权益管理-按钮 */
  RIGHTS_MANAGEMENT_BUTTON = 'rights-management-button',

  /** 项目管理-新建权益 */
  RIGHTS_FORM = 'rights-form',
  /** 项目管理-新建权益-按钮 */
  RIGHTS_FORM_BUTTON = 'rights-form-button',

  /** 项目管理-权益发放记录-总公司 */
  RIGHTS_RECORD = 'rights-record',
  /** 项目管理-权益发放记录-总公司-按钮 */
  RIGHTS_RECORD_BUTTON = 'rights-record-button',

  /** 项目管理-权益发放记录-子公司 */
  RIGHTS_RECORD_SUBSIDIARY = 'rights-record-subsidiary',
  /** 项目管理-权益发放记录-子公司-按钮 */
  RIGHTS_RECORD_SUBSIDIARY_BUTTON = 'rights-record-subsidiary-button',

  /** 待办事项 */
  TODOS = 'todos',
  /** 待办事项-待办事项 */
  TODOS_LIST = 'todos-list',
  /** 待办事项-待办事项-按钮 */
  TODOS_LIST_BUTTON = 'todos-list-button',

}