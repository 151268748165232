import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';


export default [
  {
    path: routeNames.orderManagement,
    component: () => import('../../pages/orderManagement/orderManagement'),
    meta: {
      title: '订单管理'
      , code: EAuthCode.ORDER_LIST_MANAGEMENT
    }
  },
  {
    path: routeNames.orderDetail,
    component: () => import('../../pages/orderManagement/orderDetail'),
    meta: {
      title: '订单详情',
      hideInMenu: true
      ,code:EAuthCode.ORDER_LIST_MANAGEMENT
    }
  },
  {
    path: routeNames.afterSaleManagement,
    component: () => import('../../pages/orderManagement/afterSaleManagement'),
    meta: {
      title: '售后管理'
      ,code:EAuthCode.AFTER_SALE_MANAGEMENT
    }
  }
] as TRoutes[]
