import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.integralSetting,
    component: () => import('../../pages/integralManagement/integralSetting'),
    meta: { title: '积分设置' 
      , code:EAuthCode.INTEGRAL_SETTING
    }
  },
  {
    path: routeNames.integralDetails,
    component: () => import('../../pages/integralManagement/integralDetails'),
    meta: { title: '积分明细' 
      , code:EAuthCode.INTEGRAL_DETAILS
    }
  }
] as TRoutes[]
