import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';


export default [
  {
    path: routeNames.classifyManagement,
    component: () => import('../../pages/storeManagement/classifyManagement'),
    meta: {
      title: '分类管理'
       ,code:EAuthCode.CLASSIFY_MANAGEMENT
    }
  },
  {
    path: routeNames.subclassManagement,
    component: () => import('../../pages/storeManagement/subclassManagement'),
    meta: {
      title: '子分类管理',
      hideInMenu: true
      // ,code:EAuthCode.SUBCLASSIFY_MANAGEMENT
    }
  },
  {
    path: routeNames.goodManagement,
    component: () => import('../../pages/storeManagement/goodManagement'),
    meta: {
      title: '商品管理'
      ,code:EAuthCode.GOOD_MANAGEMENT
    }
  },
  {
    path: routeNames.goodFormArtwork,
    component: () => import('../../pages/storeManagement/goodFormArtwork'),
    meta: {
      title: '新增商品-艺术品',
      hideInMenu: true
      // ,code:EAuthCode.ADD_ARTWORK
    }
  },
  {
    path: routeNames.goodFormItem,
    component: () => import('../../pages/storeManagement/goodFormItem'),
    meta: {
      title: '新增商品-实物',
      hideInMenu: true
      // ,code:EAuthCode.ADD_ITEM
    }
  },
  {
    path: routeNames.goodFormTicket,
    component: () => import('../../pages/storeManagement/goodFormTicket'),
    meta: {
      title: '新增商品-门票',
      hideInMenu: true
      // ,code:EAuthCode.ADD_TICKET
    }
  },
  {
    path: routeNames.buyInfo,
    component: () => import('../../pages/storeManagement/buyInfo'),
    meta: {
      title: '购买须知'
      ,code:EAuthCode.BUY_INFO
    }
  },
  {
    path: routeNames.transferManagement,
    component: () => import('../../pages/storeManagement/transferManagement'),
    meta: {
      title: '转账管理'
      ,code:EAuthCode.TRANSFER_MANAGEMENT
    }
  }
] as TRoutes[]
