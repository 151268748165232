import { FC, memo, useContext, useState } from 'react';
import { Button, Card, Form, Input, message, Statistic } from 'antd';
import styles from './index.module.less';
import Logo from '~/assets/images/logo.png';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import globalContext from '~/contexts/global.context';
import { useForm } from 'antd/lib/form/Form';
import { useLocation, useHistory } from 'react-router-dom';
import { api } from '~/request';
import { routeNames } from '~/routes/const';
import CryptoJS from 'crypto-js';
import { ECodeScene } from '~/enums/ECodeScene';

const { Item } = Form;
const { Countdown } = Statistic


const Component: FC = () => {

  const { applicationName } = useContext(globalContext)

  const history = useHistory()
  const [form] = useForm()
  const { state = {} } = useLocation<{
    from?: string;
    scene?: ECodeScene;
  }>()
  const [passwordType, setPasswordType] = useState('password')
  const [showCountDown, setShowCountDown] = useState(false)

  const scene = state.scene || ECodeScene.REG

  function handleFinish() {
    form.validateFields().then((value) => {
      const { mobile, password, code } = value;

      const srcs = CryptoJS.enc.Utf8.parse(password);
      const key = CryptoJS.enc.Utf8.parse('sdidASE5F41S5Dm7')
      const encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString();

      // console.log(password, encrypted, 'mh8el08fV54wqQH3roDr4Q==')
      const updateData = { mobile, password: encrypted, code };
      if (scene === ECodeScene.REG) {
        api['auth/register_POST'](updateData)
          .then(({ data }) => {
            window.localStorage.setItem('Authorization', 'Bearer ' + (data || ''));
            history.replace(routeNames.eTable)
          })
      } else {
        api['auth/forgot_POST'](updateData).then(() => {
          message.success('密码修改成功')
          setTimeout(() => {
            history.replace(routeNames.login)
          }, 1000);
        }).catch(() => {
          message.error('密码修改失败')
        })
      }
    })
  }

  const handleCodeClick = async () => {
    try {
      const value = await form.validateFields(['mobile'])
      try {
        setShowCountDown(true)
        await api['sms/verifyCode_GET']({ ...value, scene })
        message.success('验证码已经发送')
      } catch (error) {
        message.error('验证码发送失败')
        setShowCountDown(false)
      }
    } catch (error) {
      return message.error('请先填写手机号码')
    }
  }

  return (
    <div className={styles.component}>
      <Card className={styles.card}>
        <div style={{ textAlign: 'center' }}>
          <img src={Logo} alt="" className={styles.logo} />
        </div>
        <div className={styles.desc}>{applicationName}</div>

        <h2>{scene === ECodeScene.FORGOT ? '忘记密码' : '注册'}</h2>
        <Form form={form} onFinish={handleFinish} >
          <Item label="手机号码" name="mobile" rules={[{ required: true, message: '请输入手机号码' }]}>
            <Input placeholder="请输入手机号码" size="large" />
          </Item>

          <Item label="短信验证" name="code" rules={[{ required: true, message: '请输入短信验证码' }]}>
            <Input placeholder="请输入短信验证码" size="large" suffix={
              showCountDown ?
                <Countdown
                  value={Date.now() + 6000}
                  valueStyle={{ fontSize: 14, color: '#cccccc' }}
                  format="ss"
                  onFinish={() => setShowCountDown(false)}
                />
                :
                <Button type="link" onClick={handleCodeClick}>获取验证码</Button>
            }
            />
          </Item>

          <Item label="登陆密码" name="password" rules={[{ required: true, message: '请输入密码' }]}>
            <Input
              placeholder="请输入密码"
              size="large"
              type={passwordType}
              suffix={passwordType === 'password' ?
                <EyeInvisibleOutlined onClick={() => setPasswordType('text')} /> :
                <EyeOutlined onClick={() => setPasswordType('password')} />
              }
            />
          </Item>

          <Item >
            <Button type="primary" block size="large" htmlType="submit">注册</Button>
          </Item>
        </Form>

      </Card>
    </div>
  )
}

Component.defaultProps = {};
Component.displayName = 'MMPLogin';

const MMPLogin = memo(Component);
export default MMPLogin;