import { createBrowserHistory } from 'history'
import { generateRouteAndMenu, IRoute, routeNames } from './const'
import BasicLayout from '~/layouts/basicLayout'
import MMPNOMatch from '~/pages/404'
import MMPNoAuth from '~/pages/403'

import generate from './modules/generates'
import _example from './modules/_example'
import departmentManagement from './modules/departmentManagement'
import basicSetting from './modules/basicSetting'
import categoryStorage from './modules/categoryStorage'
import decorationSetting from './modules/decorationSetting'

import Login from '../pages/login'
import Register from '../pages/register'
import { AppstoreOutlined, BgColorsOutlined, ClusterOutlined, FundOutlined, SettingOutlined, TableOutlined,
    ProfileOutlined, HddOutlined, NotificationOutlined, BuildOutlined, TransactionOutlined, ReadOutlined, DatabaseOutlined,AreaChartOutlined,ShoppingOutlined,AccountBookOutlined,WalletOutlined,TeamOutlined} from '@ant-design/icons'
import todos from './modules/todos'
import memberManagement from './modules/memberManagement'
import inviteManagement from './modules/inviteManagement'
import integralManagement from './modules/integralManagement'
import muliManagement from './modules/muliManagement'
import contentManagement from './modules/contentManagement'
import brandActivityManagement from './modules/brandActivityManagement'
import storeManagement from './modules/storeManagement'
import orderManagement from './modules/orderManagement'
import projectManagement from './modules/projectManagement'
import reportManagement from './modules/reportManagement'

export const history = createBrowserHistory()

export const routers: IRoute[] = [
  {
    path: routeNames.login,
    component: Login,
    meta: { title: '登陆', sync: true, noLogin: true }
  },
  {
    path: routeNames.register,
    component: Register,
    meta: { title: '注册', sync: true, noLogin: true }
  },
  {
    path: routeNames[403],
    component: MMPNoAuth,
    meta: { title: '权限已变更', sync: true, noLogin: true }
  },
  {
    path: '/',
    layout: BasicLayout,
    redirect: true,
    children: [
      // { name: '自动表格', icon: TableOutlined, children: generate },
      // { name: '模板', icon: FundOutlined, children: _example },
      // { name: '基础设置', icon: SettingOutlined, children: basicSetting },
      // { name: '类目管理', icon: ClusterOutlined, children: categoryStorage },
      //{ name: '装修设置', icon: BgColorsOutlined, children: decorationSetting },
      { name: '系统管理', icon: AppstoreOutlined, children: departmentManagement },
      { name: '待办事项', icon: ProfileOutlined, children: todos },
      { name: '会员管理', icon: HddOutlined, children: memberManagement },
      { name: '邀请有礼活动管理', icon: NotificationOutlined, children: inviteManagement },
      { name: '积分管理', icon: BuildOutlined, children: integralManagement },
      { name: '木里值管理', icon: TransactionOutlined, children: muliManagement },
      { name: '内容管理', icon: ReadOutlined, children: contentManagement },
      { name: '线下品牌活动管理', icon: DatabaseOutlined, children: brandActivityManagement },
      { name: '积分商城管理', icon: ShoppingOutlined, children: storeManagement},
      { name: '订单管理', icon: AccountBookOutlined, children: orderManagement},
      { name: '项目管理', icon: WalletOutlined, children: projectManagement},
      { name: '报表统计', icon: AreaChartOutlined, children: reportManagement},
      {
        path: routeNames[403],
        component: MMPNoAuth,
        meta: { title: '没有权限', sync: true, noLogin: true }
      },
      { path: '*', component: MMPNOMatch, meta: { sync: true, noLogin: true } }
    ]
  }
  // {
  //   path: '*',
  //   component: MMPNOMatch,
  //   meta: {
  //     title: '页面不存在',
  //     noAuth: true
  //   }
  // }
]

const { routes } = generateRouteAndMenu(routers)

export function getMenus() {
  return generateRouteAndMenu(routers).menus;
}

export { routes }
