import { IRequestConfig, IResponse } from '~/modules/@wmeimob/request/src/types/fetch-type';
import { IInstance } from './../modules/@wmeimob/request/src/index';
import { history } from '~/routes'
import { notification } from 'antd';

/**
 * 添加自定义头前置拦截器
 * @param config
 */
export function addHeaderRequestInterceptor(config: IRequestConfig) {
  const Authorization = window.localStorage.getItem('Authorization')
  if (Authorization) {
    config.headers = config.headers || {};
    config.headers.Authorization = Authorization;
  }
  return config;
}

/**
 * 响应处理拦截器
 * @param res
 */
export function dealResponseInterceptro(res: IResponse) {
  const { status, data, url } = res;
  let description: string | null = null;
  if (status === 401 || (data.code && data.code === 403)) {
    const target = window.location.search.slice(1);
    const isLoginPage = /\/login\?/.test(target);

    if (!isLoginPage) {
      history.replace(`/login`)
    }
    description = '';
  } else if (status === 200 && data.code !== 0) {
    description = data.msg;
  } else if (status < 200 || status >= 300) {
    if (data.error) {
      description = data.error.message;
    } else if (status && status > 0) {
      description = `url:${url},statusCode:${status}`;
    } else if (status && status === -1) {
      description = '网络请求失败，请检查您的网络。';
    } else {
      description = '未知错误，万分抱歉！';
    }
  }

  if (description !== null) {
    if (description) {
      notification.error({ message: '请求错误', description });
    }
    throw new Error();
  }

  return res;
}

export default function interceptors(instance: IInstance) {
  // 请求拦截器
  instance.requestInterceptors.use(addHeaderRequestInterceptor);

  // 响应拦截器
  instance.responseInterceptors.use(dealResponseInterceptro);
  instance.responseInterceptors.use(({ data }) => data)
}